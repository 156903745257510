import { render, staticRenderFns } from "./CasterReferenceStationDetails.vue?vue&type=template&id=078336ea&scoped=true"
import script from "./CasterReferenceStationDetails.vue?vue&type=script&lang=js"
export * from "./CasterReferenceStationDetails.vue?vue&type=script&lang=js"
import style0 from "./CasterReferenceStationDetails.vue?vue&type=style&index=0&id=078336ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "078336ea",
  null
  
)

export default component.exports