<template>
<div>

<b-container class="mainpagecont">

    <b-row>
        <b-col class="col-12 col-sm-4 mt-3"><h4>Stock Reports</h4></b-col>

        <b-col class="col-12 col-sm-4 mt-3">
          <CasterSearchSuggestions 
            placeholder="search stock"
            :clearsearch="clearsearch"
            suggestionType = "4"
            :suggestionFetchResponse="fetchResponse" 
            v-on:textsearch=updateSearch 
            v-on:clearsearch=clearFilter
            />
        </b-col>

    </b-row>

    <CasterOptionsFilter 
            :resellerid="resellerid" 
            :dealerid="dealerid" 
            :roversshow="false" 
            :companysshow="false" 
            :clearsearch="clearsearch"
            v-on:resellersel=updateResellerFilter 
            v-on:dealersel=updateDealerFilter  
            />     

   <b-row class="mt-3">

                <b-col class="col-12 col-sm-3 mt-3">
                    <label>Start Date</label>
                    <VueCtkDateTimePicker
                            v-model="datesel1"
                            format='DD-MM-YYYY'
                            :dark="true"
                            :no-value-to-custom-elem="true"
                            :only-date="true"
                            >
                            <b-form-input 
                                type="search"
                                v-model="datesel1" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput2"
                                size="sm"
                                />
                            </VueCtkDateTimePicker>    
                </b-col>  

                <b-col class="col-12 col-sm-3 mt-3">
                    <label>End Date</label>
                    <VueCtkDateTimePicker
                            v-model="datesel2"
                            format='DD-MM-YYYY'
                            :dark="true"
                            :no-value-to-custom-elem="true"
                            :only-date="true"
                            >
                            <b-form-input 
                                type="search"
                                v-model="datesel2" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput2"
                                size="sm"
                                />
                            </VueCtkDateTimePicker>    
                </b-col>
                
                <b-col class="col-12 col-sm-3 mt-5">
                    <b-button variant="outline-success" size="sm" @click.stop="fetchStock()">set dates</b-button>
                </b-col>
            
    </b-row>         
   
   <b-row class="mt-5">     

    <b-col class="col-12 mt-1">
             <b-table
                :items="stock" 
                :fields="fields"
                :busy="isBusy"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                dark
                >
                <template #table-busy>
                    <div class="text-center my-2" >
                    <b-spinner class="align-middle" style="color:orange"></b-spinner>
                    <strong style="color:orange"> Loading...</strong>
                    </div>
                </template>   
           </b-table>         
    </b-col>

</b-row>  
  

</b-container>
	
</div> 
</template>



<script>
    import CasterSearchSuggestions from './CasterSearchSuggestions'
    import CasterOptionsFilter from './CasterOptionsFilter'
    import axios from 'axios'
    export default {
        name:'CasterSubscriptionsStockReports',
        components:{
            CasterSearchSuggestions,
            CasterOptionsFilter
        }, 
        data(){
		return {
            token:'',
            userid:0,
            stock:[],
            resellerid:0,
            datesel1:'',
            datesel2:'',
            dealerid:0,
            clearsearch:false,
            fetchResponse:'',
            stext:'',
            isBusy:true,
            fields:[
                { key: 'reseller', label: 'Reseller'  },
                { key: 'dealer', label: 'Dealer' },
                { key: 'type', label: 'Type' },
                { key: 'quant', label: 'Quant' },
                { key: 'dealer_po', label: 'PO' },
                { key: 'user', label: 'User' },
                { key: 'date', label: 'Date' }
                ],
            };
		
		},
		created(){
            this.$store.commit('setbasesdatapoll',0)
            this.$store.commit('setcompanyid',0)
            this.token = this.$store.getters.token
            this.userid = this.$store.getters.user.id
            this.resellerid = this.$store.getters.resellerid
            this.dealerid = this.$store.getters.dealerid
        },

        mounted(){
           this.fetchStock()
        },

       
		methods: {

            fetchStock(){
                this.isBusy = true
                axios.get('/api/getstocking',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.userid,
                        token:this.token,
                        reseller:this.resellerid,
                        dealer:this.dealerid,
                        start:this.datesel1,
                        end:this.datesel2,
                        stext: this.stext
                    }
                })
                .then((response) => {
                        this.isBusy = false
                        if(response.data.code == 999){
                            console.log(response.data)
                            alert('Exception')
                        } else {
                        this.stock = response.data.stock
                        if(this.stock.length != 0 && this.stext != ''){this.fetchresponse = this.stext}
                        }
                    }) .catch(err => {
                    console.log(err)
                    });

            },

            updateResellerFilter(id){
                this.resellerid = id
                this.fetchStock()
            },

            updateDealerFilter(id){
                this.dealerid = id
                this.fetchStock()
            },

            updateSearch(text){
                this.stext = text
                this.fetchStock()
            },

            clearFilter(){
                this.stext = ''
                this.fetchStock()
            },

            setFetchResponse(text){
                this.fetchResponse = text
            },

             menuLink(name){
                this.$router.push({ name: name, params: {} }).catch(()=>{})
            },
        }
	
	}

</script> 