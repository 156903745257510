//--Modules---------------------------------------------------------------------------------------//
import Vue from 'vue'
import App from './App.vue'

//url handling
import VueRouter from 'vue-router'
Vue.use(VueRouter)

//Cross componant varibles store
import store from './store'
import Vuex from 'vuex'
Vue.use(Vuex)

//html requests
import axios from 'axios';
axios.defaults.baseURL = 'https://ip-rtk-aws.com';

//date/time picking
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

//popup alerts (being phased out)
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@sweetalert2/theme-dark/dark.min.css';
Vue.use(VueSweetalert2)

//User input validation
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate, { 
	fieldsBagName: 'veeFields',
	events: ''     
});

//Structural framework,
import 'bootstrap'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

//Rich text editor
import "tinymce/tinymce"
import "tinymce/themes/silver" 
import "../public/tinymce/skins/ui/Cloudbase/skin.min.css"
import "../public/tinymce/skins/ui/Cloudbase/content.min.css"



//--Componants------------------------------------------------------------------------------------//
import Test from './components/test.vue'

import SystemLogin from './components/SystemLogin.vue'
import NoLoginPage from './components/NoLoginPage.vue'
import ResetPasswordForm from './components/ResetPasswordForm.vue'
import ForgotPassword from './components/ForgotPassword.vue'
import DashBoardCaster from './components/DashBoardCaster.vue'

import CasterAdminHome from './components/CasterAdminHome.vue'
import CasterResellerHome from './components/CasterResellerHome.vue'
import CasterRoversLogged from './components/CasterRoversLogged.vue'
import CasterReferenceStation from './components/CasterReferenceStation.vue'
import CasterReferenceStations from './components/CasterReferenceStations.vue'
import CasterSubscriptions from './components/CasterSubscriptions.vue'
import CasterSubscriptionsStockDealer from './components/CasterSubscriptionsStockDealer.vue'
import CasterRoverManage from './components/CasterRoverManage.vue'
import CasterNetworkDisplay from './components/CasterNetworkDisplay.vue'
import CasterSimms from './components/CasterSimms.vue'

import CasterResellers from './components/CasterResellers.vue'
import CasterDealers from './components/CasterDealers.vue'
import CasterDealer from './components/CasterDealer.vue'
import CasterCompanies from './components/CasterCompanies.vue'
import CasterCompany from './components/CasterCompany.vue'
import CasterCompanySubscriptions from './components/CasterCompanySubscriptions.vue'
import CasterCompanyRovers from './components/CasterCompanyRovers.vue'
import CasterRovers from './components/CasterRovers.vue'
import CasterRover from './components/CasterRover.vue'
import CasterUsers from './components/CasterUsers.vue'
import CasterLogRTCM from './components/CasterLogRTCM.vue'
import CasterLogMessages from './components/CasterLogMessages.vue'
import CasterLogSats from './components/CasterLogSats.vue'
import CasterLogGGA from './components/CasterLogGGA.vue'
import CasterEventLog from './components/CasterEventLog.vue'
import CasterEmails from './components/CasterEmails.vue'
import CasterStatsNetworkLogins from './components/CasterStatsNetworkLogins.vue'
import CasterStatsNTRIPClients from './components/CasterStatsNTRIPClients.vue'
import CasterStatsSubs from './components/CasterStatsSubs.vue'
import CasterUsersAdmin from './components/CasterUsersAdmin.vue'
import CasterReseller from './components/CasterReseller.vue'
import CasterSubscriptionsStockReseller from './components/CasterSubscriptionsStockReseller.vue'
import CasterSubscriptionsStockReports from './components/CasterSubscriptionsStockReports.vue'
import CasterRoverHistory from './components/CasterRoverHistory.vue'
import CasterEventLogDealer from './components/CasterEventLogDealer.vue'
import CasterEventLogReseller from './components/CasterEventLogReseller.vue'
import CasterAudit from './components/CasterAudit.vue'
import CasterEmailTemplates from './components/CasterEmailTemplates.vue'
import CasterOrders from './components/CasterOrders.vue'
import CasterSystemStatus  from './components/CasterSystemStatus.vue'
import CasterGDPR  from './components/CasterGDPR.vue'
import CasterBases from './components/CasterBases.vue'
import CasterReports from './components/CasterReports.vue'
import CasterSubsLog from './components/CasterSubsLog.vue'
//--Vue-Router------------------------------------------------------------------------------------//
const router = new VueRouter({   
	
  mode: 'history', 
 
  
 routes: [
  {
          path: '/',
          name:'nologin', 
          component: NoLoginPage 
   },

   {
    path: '/test',
    name:'test', 
    component: Test 
},
    
   {
          path: '/notauth',
          name:'notauth', 
          component: NoLoginPage 
  },
   {
          path: '/login/:dealer',
          name:'systemlogin',
          component: SystemLogin  
  },
  {
        path: '/reset-password/:dealer', 
        name: 'reset-password', 
        component: ForgotPassword, 
        meta: { 
            auth:false 
        } 
    },
    { 
      path: '/password/reset?email=:email&token=:token&dealer:dealer',  
      name: 'reset-password-form', 
      component: ResetPasswordForm, 
      meta: { 
          auth:false  
      }
  },
  {
    path: '/home', 
    name: 'dashboardcaster',
    component: DashBoardCaster, 


    children: [
    {
      path: '/sysadmin/network', 
      name: 'casteradminhome', 
      component: CasterAdminHome
    },
    {
      path: '/reseller/networkadmin', 
      name: 'casterresellerhome', 
      component: CasterResellerHome
    },
    {
      path: '/network/loggedrovers',
      name: 'casterroverslogged',
      component: CasterRoversLogged
    },
    {
      path: '/network/referencestations',
      name: 'casterreferencestations',
      component: CasterReferenceStations 
    },
   
   
    {
      path: '/network/display',
      name: 'casternetworkdisplay',
      component: CasterNetworkDisplay 
    },
    {
      path: '/manage/sims', 
      name: 'castersimms',
      component: CasterSimms
    },
    {
      path: '/manage/resellers',
      name: 'casterresellers',
      component: CasterResellers
    },
    {
      path: '/manage/dealers',
      name: 'casterdealers',
      component: CasterDealers
    },
    {
      path: '/dealer/network/:id',
      name: 'casterdealer',
      component: CasterDealer
    }, 
    {
      path: '/manage/companies',
      name: 'castercompanies',
      component: CasterCompanies
    },
    {
      path: '/manage/orders',
      name: 'casterorders',
      component: CasterOrders
    },
    {
      path: '/subscriptionsstockdealer',  
      name: 'castersubscriptionsstockdealer',
      component: CasterSubscriptionsStockDealer   
    },
    {
      path: '/subscriptionsstockreseller',  
      name: 'castersubscriptionsstockreseller',
      component: CasterSubscriptionsStockReseller   
    },
    {
      path: '/subscriptionsstockreports',  
      name: 'subscriptionsstockreports',
      component: CasterSubscriptionsStockReports   
    },
    {
      path: '/manage/subscriptions',   
      name: 'castersubscriptions',
      component: CasterSubscriptions 
    },
    {
      path: '/manage/companysubscriptions',
      name: 'castercompanysubscriptions',
      component: CasterCompanySubscriptions
    },
    {
      path: '/manage/companyrovers',
      name: 'castercompanyrovers',
      component: CasterCompanyRovers
    },
    {
      path: '/manage/bases',
      name: 'casterbases',
      component: CasterBases
    },
    {
      path: '/company/:id',
      name: 'castercompany',
      component: CasterCompany
    },
    {
      path: '/manage/rovers',
      name: 'casterrovers',
      component: CasterRovers  
    },
    {
      path: '/manage/users',
      name: 'casterusers',
      component: CasterUsers
    },
    {
      path: '/logs/casterlogmessages',
    name: 'casterlogmessages',
    component: CasterLogMessages 
  }, 
  {
      path: '/logs/casterlogrtcm3',
      name: 'casterlogrtcm3',
      component: CasterLogRTCM 
  },   
  {
      path: '/logs/casterlogsats', 
      name: 'casterlogsats',
      component: CasterLogSats 
  },
  {
      path: '/logs/casterloggga', 
      name: 'casterloggga',
      component: CasterLogGGA 
  },
  {
      path: '/logs/castereventlog', 
      name: 'castereventlog',
      component: CasterEventLog 
  },
  {
    path: '/logs/castereventlogdealer', 
    name: 'castereventlogdealer',
    component: CasterEventLogDealer 
},  
{
    path: '/logs/castereventlogreseller', 
    name: 'castereventlogreseller',
    component: CasterEventLogReseller 
},    
{
    path: '/logs/emails',
    name: 'casteremails',
    component: CasterEmails 
 },
 {
  path: '/logs/gdpr',
  name: 'castergdpr',
  component: CasterGDPR 
 },
 {
  path: '/logs/subscriptions',
  name: 'castersubslog',
  component: CasterSubsLog
 },
 {
      path: '/stats/networklogins',
      name: 'casterstatsnetworklogins',
      component: CasterStatsNetworkLogins 
}, 
{
      path: '/stats/ntripclients', 
      name: 'casterstatsntripclients',
      component: CasterStatsNTRIPClients 
},  
{
      path: '/stats/casterstatssubs', 
      name: 'casterstatssubs',
      component: CasterStatsSubs   
},
{
      path: '/systemadmin/users',
      name: 'casterusersadmin',
      component: CasterUsersAdmin 
},
{
  path: '/systemadmin/reports',
  name: 'casterreports',
  component: CasterReports 
},
{
  path: '/systemadmin/audittrail',
  name: 'casteraudit',
  component: CasterAudit 
},
{
    path: '/systemadmin/emailtemplates',
    name: 'casteremailtemplates',
    component: CasterEmailTemplates 
},
{
      path: '/reseller/network/:id', 
      name: 'casterreseller',
      component: CasterReseller 
},
{
  path: '/rover/:id', 
  name: 'casterrovermanage',
  component: CasterRoverManage
},
{
  path: '/rover/session/:id',
  name: 'casterrover',
  component: CasterRover 
},
{
      path: '/rover/sessionhistory/:id',
      name: 'casterroverhistory',
      component: CasterRoverHistory
},
{
    path: '/network/referencestation/:id',
    name: 'casterreferencestation',
    component: CasterReferenceStation 
},  
{
  path: '/system/status',
  name: 'castersystemstatus',
  component: CasterSystemStatus  
},                      
 

    ]
  }  

]

})

//--Init------------------------------------------------------------------------------------------//
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

