<template>
<div ref="mainDiv" class="imageBorder">

    <!--Header-->
    <b-row>
        <b-col class="col-9 mt-3 textfade">SATELLITES</b-col>
        <b-col class="col-3 mt-3 text-right" >
            <b-icon-fullscreen @click.stop="fullScreen()" v-b-tooltip.hover title="Expand" />
        </b-col>           
    </b-row> 

    <!--Table-->
    <b-row class="mt-3" v-show="allSats.length > 0">
        <b-col class="col-12">
            <b-table
            striped 
            responsive 
            hover 
            :items="allSats" 
            :fields="fields"
            :tbody-tr-class="rowClass"
            thead-tr-class="rowHClass"
            :sticky-header="`${height.contentHeight}px`"
            sort-icon-left
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            dark
            >
                
                <template #cell(Timestamp)="data">
                    <span v-b-tooltip.hover :title="formatDate(data.item.Timestamp)">
                        {{ formatTime(data.item.Timestamp) }}
                    </span>
                </template>
                

            </b-table>
        </b-col>
    </b-row>

    <b-row class="mt-0" v-show="allSats.length == 0">
        <b-col class="col-12 text-center">No Satellite Data</b-col>
    </b-row>    

    <!--Fullscreen Modal-->
    <b-modal ref="full-screen" size="lg"  title="SATELLITES" hide-footer centered content-class="darkmodal">

        <b-row class="mt-3">
            <b-col class="col-12">

                <b-table 
                striped 
                responsive 
                hover 
                :items="allSats" 
                :fields="fields"
                :tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :sticky-header="`${height.contentHeight}px`"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                dark
                >

                    <template #cell(Timestamp)="data">
                        {{ `${ formatTime(data.item.Timestamp) } ${ formatDate(data.item.Timestamp) }` }}
                    </template>

                </b-table>
            </b-col>
                
        </b-row>
    </b-modal>

</div> 
</template>


<style>
    .ConstGpsRow{
        background-color: rgba(60,210,165,0.15) !important;
    }
    .ConstGlonassRow{
        background-color: rgba(249,232,13,0.15) !important;
    }
    .ConstBeidoRow{
        background-color: rgba(255,96,96,0.15) !important;
    }
    .ConstGalileoRow{
        background-color: rgba(222,140,255,0.15) !important;
    }
    .ConstQzssRow{
        background-color: rgba(187,164,255,0.15) !important;
    }
</style>
<style scoped>
    .sat-overflow{
        overflow-y:scroll;
    }
</style>


<script>
    let timestampDates = [];

	export default {
        name:'CasterReferenceStationSatTable',
        props:['satStore','height'], //height is passed from skyplot so they are inline
        data(){
            return {
                
                fields:[
                    { key: 'Constellation', label: 'Type', sortable: true },
                    { key: 'PRN', label: 'PRN', sortable: true},
                    { key: 'Azimuth', label: 'Azim(°)', sortable: true},
                    { key: 'Elevation', label: 'Elev(°)', sortable: true },
                    { key: 'Timestamp', label: 'Time', sortable: true},
                    { key: 'SNR', label: 'SNR', sortable: true},
                    { key: 'Plot_id', label: 'Plot', sortable: true},
                    { key: 'id', label: 'id', sortable: true},
                ],
                sortBy: 'type',
                sortDesc: false,
                allSats:[],
            };
		},
        created(){
            
        },
        computed:{
            satStoreState(){
                return this.satStore;
            },
            maxHeight(){
                return this.height;
            }
        },
        watch:{
            satStoreState:{
                //looking at "visible" inside satStore
                handler(){
                    this.addSatConstellationsToAllSats();
                },
                deep:true
            },
            maxHeight(){
                this.$refs.mainDiv.style.maxHeight = `${this.height.fullHeight}px`;
            }
        },
		methods: {
            fullScreen(){
                this.$refs['full-screen'].show()
            },
            addSatConstellationsToAllSats(){
                this.allSats = [];
                Object.values(this.satStore).forEach( (constel) => {
                    if(!constel.visible)return;
                    this.allSats = this.allSats.concat(constel.sats);
                })
            },
            formatTime(timestamp) {  
                if(timestamp == undefined){ return '-'}              
                const date = new Date(timestamp.replace(" ", "T"));
                date.setMinutes( date.getMinutes() - date.getTimezoneOffset() )
                const time = date.toLocaleTimeString();
                timestampDates.push(date);
                return time;
            },
            formatDate(timestamp){
                if(timestamp == undefined){ return '-'}   
                const date = new Date(timestamp.replace(" ", "T"));
                date.setMinutes( date.getMinutes() - date.getTimezoneOffset() )
                return date.toDateString();
            },
            rowClass(item, type) {
                if (!item || type !== 'row') return;
                switch (item.Constellation) {
                    case 'P':
                        return 'ConstGpsRow rowClassSm'; 
                    case 'L':
                        return 'ConstGlonassRow rowClassSm'; 
                    case 'B':
                        return 'ConstBeidoRow rowClassSm';
                    case 'A':
                        return 'ConstGalileoRow rowClassSm'; 
                    case 'Q':
                        return 'ConstQzssRow rowClassSm'; 
                    default:
                        return 'rowClassSm';
                }
            },		
        }
	}

</script> 
