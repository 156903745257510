<template>
<div>

<b-container class="mainpagecont">

    <b-row>
        <b-col class="col-12 col-sm-8 mt-3"><h4>Stock Subscriptions</h4></b-col>
        <b-col class="col-12 col-sm-4 mt-3 text-right"  v-if="roleid == 1">
                <label>Resellers</label>
                <b-form-select
                            v-model="resellersel"
                            :options="resellers"
                            v-on:change="changeReseller()"
                            class="darkinput2"
                            size="sm" 
                            />
        </b-col>
    </b-row>

<b-row>

   <b-col class="col-12 col-sm-8">
   <b-row>     

    <b-col class="col-12 mt-3 mb-1">
         <h5>Reseller Subscriptions</h5>
    </b-col>

    <b-col class="col-12 col-sm-4 mt-1">
         <b-button variant="outline-success" block size="sm" @click="menuLink('subscriptionsstockreports')" v-if="roleid == 1 || user.id == 559">REPORTS</b-button>
    </b-col>
    
    <b-col class="col-12 col-sm-4 offset-sm-4 text-right mt-1">
         <b-button variant="outline-success" block size="sm" @click="addResellerSub()" v-if="roleid == 1 || user.id == 559">ADD SUBSCRIPTIONS</b-button>
    </b-col>

    <b-col class="col-12 mt-1" v-if="subsreseller.length !==0">
             <b-table
                :items="subsreseller" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                dark
                />
    </b-col>

    <b-col class="col-12 mt-5" v-else>
                <b-col class="col-12 pt-2 pb-2">
                <b-button variant="danger" block disabled>NO SUBSCRIPTIONS IN STOCK</b-button>
                </b-col>
    </b-col>

   </b-row>
   </b-col>

</b-row>  
  
<b-row class="mt-3 pb-5">

    <b-col class="col-12 mt-5">
         <h5>Dealer Subscriptions</h5>
    </b-col>

    <b-col class="col-12 mt-1">

            <b-row > 
                    <b-col class="col-12 col-sm-8 mt-3" v-for="dealer in subs" :key="dealer.id">
                        <b-row>
                            <b-col class="col-6">
                                <h6>{{ dealer.dealer }}</h6>
                             </b-col>

                             <b-col class="col-6 text-right">
                                <b-button variant="outline-success" size="sm" @click="addSub(dealer)">ADD SUBSCRIPTIONS</b-button>
                            </b-col> 

                        </b-row>      
                        
                        <b-row class="mt-3">
                            <b-col class="col-12" v-if="dealer.subs.length !==0">
                            <b-table
                                :items="dealer.subs" 
                                :fields="fields"
                                tbody-tr-class="rowClass"
                                thead-tr-class="rowHClass"
                                dark
                                />
                            </b-col>

                            <b-col class="col-12 pt-3 pb-3" v-else>
                                <b-button variant="danger" block disabled>NO SUBSCRIPTIONS IN STOCK</b-button>
                            </b-col>

                        </b-row>

                    </b-col>

      
            </b-row>  

    </b-col>
</b-row>    

<b-modal ref="add-sub" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterDealerAddsub
    :reseller="resellersel"
    :dealer="dealer" 
    v-on:closemodal="closemodal()"
    v-on:updatemodal="updatemodal()"
    />
</b-modal>    

<b-modal ref="add-sub-reseller" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterResellerAddsub
   :reseller="resellersel"
    v-on:closemodal="closemodal()"
    v-on:updatemodal="updatemodal()"
    />
</b-modal> 

</b-container>
	
</div> 
</template>



<script>
    import CasterDealerAddsub from './CasterDealerAddsub'
    import CasterResellerAddsub from './CasterResellerAddsub'
    import axios from 'axios'
    export default {
        name:'CasterSubscriptionsStockReseller',
        components:{
            CasterDealerAddsub,
            CasterResellerAddsub
        }, 
        data(){
		return {
            roleid:0,
            resellers:[],
			resellersel:0,
            dealersel:0,
            companysel:0,
            dealer:{},
            modaltitle:'',
            subsreseller:[],
            subs:[],
            fields:[
                { key: 'text', label: 'Type'  },
                { key: 'count', label: '# Stock' }
                ],
            user:{},
            display:false
            };
		
		},
		created(){
            this.token = this.$store.getters.token
            this.roleid = this.$store.getters.roleid
            this.user = this.$store.getters.user
            this.$store.commit('setbasesdatapoll',0)
            this.$store.commit('setcompanyid',0)
            this.resellersel = this.$store.getters.resellerid
            
        },

        mounted(){
            if(this.user.dealer == 0){
            this.fetchResellers()
            this.fetchResellerStockSubs()
            this.fetchStockSubs()
            } else this.menuLink('castersubscriptionsstockdealer')
        },

       
		methods: {

            fetchResellers(){

				axios.get('/api/casterbusinesses',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token
                     }
                })
				.then((response) => {
                    this.resellers= response.data.CasterBusiness
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			}, 

           fetchStockSubs(){

				axios.get('/api/casterntripsubscriptionsstocklist',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.resellersel
                     }
                })
				.then((response) => {
                    this.subs = response.data
                    this.display = true
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},

            fetchResellerStockSubs(){

				axios.get('/api/casterntripsubscriptionsstocklist',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         reseller:this.resellersel,
                         admin:1
                     }
                })
				.then((response) => {
                    this.subsreseller = response.data
                    this.display = true
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},

            changeReseller(){
                this.fetchResellerStockSubs()
                this.fetchStockSubs()
            },

            addSub(dealer){
               this.dealer = dealer
               this.modaltitle = 'Add Dealer Subscription'
               this.$refs['add-sub'].show()
           },

           addResellerSub(){
               this.modaltitle = 'Add Reseller Subscription'
               this.$refs['add-sub-reseller'].show()
           },

           closemodal(){
                this.$refs['add-sub'].hide()
                this.$refs['add-sub-reseller'].hide()
            },

            updatemodal(){
                this.$refs['add-sub'].hide()
                this.$refs['add-sub-reseller'].hide()
                this.fetchResellerStockSubs()
                this.fetchStockSubs()
            },

             menuLink(name){
                this.$router.push({ name: name, params: {} }).catch(()=>{})
            },
        }
	
	}

</script> 