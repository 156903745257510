<template>
<div>

<b-row>

<b-col class="col-12 col-md-6 col-lg-3 mt-3">
    <label>Supplier</label>
    <b-form-select
                v-model="simsuppliersel"
                :options="simsuppliers"
                class="darkinput2"
                size="sm"
                @change = "setStatus" 
                />
</b-col>

<b-col class="col-12 col-md-6 col-lg-3 mt-3">
    <label>Subscription Status</label>
    <b-form-select
                v-model="substatussel"
                :options="substatuss"
                class="darkinput2"
                size="sm"
                @change = "setStatus" 
                />
</b-col>

<b-col class="col-12 col-sm-3 mt-5">
          <b-form-checkbox v-model="showstock" inline size="md" @change="showStock" />
          <label style="position: absolute;"> Stock only</label>
        </b-col>

        <b-col class="col-12 col-sm-3 mt-5" v-if="isadmin">
          <b-form-checkbox v-model="showbases" inline size="md" @change="showBases" />
          <label style="position: absolute;"> Bases only</label>
        </b-col>

<b-col class="col-2 mt-3">
        <b-button variant="outline-success" size="sm" @click="resetSearch()" style="margin-top:32px"> reset search </b-button>
    </b-col>

</b-row>

<b-row class="mt-3">
    <b-col class="col-12 col-md-6 mt-3" v-if="total > limit">1 - {{ rend }} of {{ total }} Results</b-col>
    <b-col v-else class="col-12 col-md-6 mt-3">{{ total }} Result<span v-if="total != 1">s</span></b-col>

    <b-col class="col-12 col-md-6 mt-3 text-right" v-if="isadmin && simsuppliersel > 0">
        <b-button variant="outline-success" size="sm" @click.stop="refreshData">Refresh SIM data</b-button>
    </b-col>
</b-row>    


<b-row class="mt-3 mb-5" >

      <b-col>
             <b-table
                class ="nowrap"
                ref = "simms-table" 
                responsive 
                hover 
                sticky-header="600px"
                :items="displaysimms" 
                :fields="fields"
                :sort-by="sortBy"
                @head-clicked="onSortChanged"
                :no-local-sorting="nosort"
                @row-clicked="editSimm($event)"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                :busy="isBusy"
                sort-icon-left
                dark
                >
                <template #cell(show_details)="data">
                    <b-button size="sm" @click="deleteRover(data.item.value)" class="mt-1 mr-2 xsbutton" variant="outline-danger">
                        <b-icon-x />
                    </b-button>
                    <!-- <b-button size="sm" @click="editRover(data.item)" class="mt-1 mr-2 xsbutton" variant="outline-success" v-if="role=1">
                        <b-icon-pencil />
                    </b-button> -->
                </template>    

                </b-table>
            
            <b-button v-if="more" variant="outline-success" block @click.stop="showMore()">Show more results?</b-button>
            <b-button v-if="loadingmore" variant="outline-warning" disabled block>Loading results</b-button>
</b-col>
</b-row>

<b-modal ref="edit-simms" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
        <CasterSimmEdit2 :isadmin="isadmin" :id="simmsel" :simdata="simmdetail" v-on:updatemodal=updateModal() />
</b-modal>

<b-modal ref="refresh-simms" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
        <b-row>
            <b-col class="col-12">
                <p>This will refresh all sim data from {{ refreshtext }} including usages values.</p>
                <p>Can take over a minute to complete</p>
            </b-col>
            <b-col class="col-12">
                <b-button  variant="outline-success" block @click.stop="resyncSimData">Refresh SIM data</b-button>
            </b-col>
        </b-row>
        <!-- <CasterSimmEdit2 :isadmin="isadmin" :id="simmsel" :simdata="simmdetail" v-on:updatemodal=updateModal() /> -->
</b-modal> 


<b-modal ref="stage-messages" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
    <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-5">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
       
    </b-row> 

    <b-row>
        <b-col class="col-12 text-center" v-if="processing">
                <b-spinner variant="success" class="mr-2"/>
                <h5>processing</h5>
        </b-col>

        <b-col class="col-12 mt-5" v-else>
            <b-button  variant="outline-success" block @click.stop="refreshTable">Finish</b-button>
        </b-col>

    </b-row>
</b-modal> 

<b-modal v-model="isBusy" hide-header hide-footer centered content-class="loadingmodal">
        <b-row class="p-3">
            <b-col class="col-12 text-center">
                <b-spinner variant="success" class="mr-2"/>
                <h5>loading results</h5>
            </b-col>
        </b-row> 
</b-modal> 


<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<style scoped>
.nowrap td {
    white-space: nowrap !important;
}

.modal-content{
    border:none !important;
}
</style>

<script>
    import CasterSimmEdit2 from './CasterSimmEdit2'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'
    export default {
        props:['resellerid','dealerid','companyid','roverid','clearsearch','stext','simsupplier','substatus','refresh'],
        name:'CasterSimmsTable2',
        components:{
            ErrorLogging,
            CasterSimmEdit2
        },
        data(){
            return {
                test:true,
                id:0,
                token:'',
                userid:0,
                simms:[],
                displaysimms:[],
                simmsel:0,
                simsuppliersel:0,
                substatussel:0,
                simmdetail:{},
                statussel:0,
                searchtext:'',
                showstock: false,
                showbases: false,
                isadmin:false,
                datestart:'',
                dateend:'',
                simsuppliers: [
                    { value: 0, text:'All'},
                    { value: 2, text: 'Bics' },
                    { value: 1, text: 'BetterConnect' }
                ],
                substatuss: [
                    { value: 0, text: 'All' },
                    { value: 3, text: 'Active' },
                    { value: 4, text: 'Expired' },
                    { value: 2, text: 'Scheduled' },
                    { value: 5, text: 'Suspended' },
                    { value: 6, text: 'Cancelled' },

                ],
                fieldsstock: [
                    { key: 'resellername', label: 'Reseller', sortable: true },
                    { key: 'dealername', label: 'Dealer', sortable: true },
                    { key: 'number', label: 'ICCID', sortable: true },
                    { key: 'endpoint', label: 'Endpoint', sortable: true },
                    { key: 'status', label: 'Status', sortable: true },
                    { key: 'stocktype', label: 'Stock', sortable: true }
                ],
                fieldslist: [
                    { key: 'dealername', label: 'Dealer', sortable: true },
                    { key: 'companyname', label: 'Company', sortable: true },
                    { key: 'number', label: 'ICCID', sortable: true },
                    { key: 'endpoint', label: 'Endpoint', sortable: true },
                    { key: 'rovername', label: 'Rover', sortable: true },
                    { key: 'subscription[0].subscription', label: 'Type', sortable: false },
                    { key: 'subscription[0].statustext', label: 'Sub Status', sortable: false },
                    { key: 'dataused', label: 'Data Used MB', sortable: true },
                    { key: 'status', label: 'Status', sortable: true },
                    { key: 'stocktype', label: 'Stock', sortable: true }
                ],
                fieldsbases: [
                    { key: 'number', label: 'ICCID', sortable: true },
                    { key: 'endpoint', label: 'Endpoint', sortable: true },
                    { key: 'dataused', label: 'Data Used MB', sortable: true },
                    { key: 'status', label: 'Status', sortable: true }
                ],
                fieldslistbc: [
                    { key: 'dealername', label: 'Dealer', sortable: true },
                    { key: 'companyname', label: 'Company', sortable: true },
                    { key: 'number', label: 'ICCID', sortable: true },
                    { key: 'connection_id', label: 'Connection ID', sortable: true },
                    { key: 'rovername', label: 'Rover', sortable: true },
                    { key: 'subscription[0].subscription', label: 'Type', sortable: false },
                    { key: 'subscription[0].statustext', label: 'Sub Status', sortable: false },
                    { key: 'dataused', label: 'Data Used MB', sortable: true },
                    { key: 'status', label: 'Status', sortable: true }
                ],
                fieldslistboth: [
                    { key: 'dealername', label: 'Dealer', sortable: true },
                    { key: 'companyname', label: 'Company', sortable: true },
                    { key: 'number', label: 'ICCID', sortable: true },
                    { key: 'endpoint', label: 'Endpoint', sortable: true },
                    { key: 'connection_id', label: 'Connection ID', sortable: true },
                    { key: 'rovername', label: 'Rover', sortable: true },
                    { key: 'subscription[0].subscription', label: 'Type', sortable: false },
                    { key: 'subscription[0].statustext', label: 'Sub Status', sortable: false },
                    { key: 'dataused', label: 'Data Used MB', sortable: true },
                    { key: 'status', label: 'Status', sortable: true }
                ],
                orderby:'dealer',
                sortBy:'dealer',
                sortdesc:false,
                isBusy:false,
                display:false,
                errorresponse:{},
                nosubs:false,
                page:0,
                limit:50,
                more:false,
                nosort:false,
                loadingmore:false,
                total:0,
                rstart:1,
                rend:0,
                fields:[],
                observer: null,
                modaltitle:'',
                refreshtext:'',
                stagemessages:[],
                processing:false
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.userid = this.$store.getters.user.id
            this.role = this.$store.getters.roleid
            this.$store.commit('setprevroute',this.$route.name)
            this.searchtext = ''
            this.$store.commit('setsearchtext','')

            if (this.role == 1 || this.role == 5) { this.isadmin = true } // Resellers and Sys admins
            if (this.role == 10) { this.simsuppliersel = 0 } // Dealer Admin so Betterconnect and BICS
            this.datestart = moment().startOf('month').format('YYYYMMDD')
            this.dateend = moment.utc().local().format('YYYYMMDD')
            this.fields = this.fieldslist
            this.simsuppliersel = this.simsupplier
           
            this.fetchSimms()
            
        },

        mounted(){
            this.addTableEventList()
        },

        computed: {
            
            filterdata() {
                return { ...this.resellerid, ...this.dealerid, ...this.companyid, ...this.roverid };
            }
        },

         watch: {
                    filterdata: function () {
                        this.page = 0
                        this.fetchSimms()
                    },

                    simsuppliersel: function () {
                        if (this.simsuppliersel == 2) { this.fields = this.fieldslist }
                        if (this.simsuppliersel == 1) { this.fields = this.fieldslistbc }
                        if (this.simsuppliersel == 0) { this.fields = this.fieldslistboth }
                    },

                    substatus: function () {
                        this.substatussel = this.substatus
                        this.fetchSimms()
                    },

                    refresh: function () {
                        this.page = 0
                        this.fetchSimms()
                    },

                    clearsearch: function () {
                        this.page = 0
                        this.fetchSimms()
                    },
                   
                    stext: function () {
                        if(this.stext !== this.searchtext){
                            this.searchtext = this.stext
                            this.page = 0
                            this.simsuppliersel = 0
                            this.substatussel = 0
                            this.fetchSimms()
                        }
                         },
                         
                    simms: function() {
                        if(this.page > 0){
                            this.readdTableEventList
                        }
                    }
        }, 
        
methods: {

    fetchSimms() {
        this.isBusy = true
        axios.get('/api/castersimmssort', {
            headers: { "Authorization": "Bearer " + this.token },
            params: {
          userid: this.userid,
          token: this.token,
          roleid:this.role,
          reseller: this.resellerid,
          dealer: this.dealerid,
          company: this.companyid,
          rover: this.roverid,
          type:this.simsuppliersel,
          status: this.statussel,
          stext: this.searchtext,
          stock: this.showstock,
          bases: this.showbases,
          page: this.page,
          limit: this.limit,
          orderby:this.orderby,
          sortdesc:this.sortdesc,
          substatus: this.substatussel,
        }
      })
        .then((response) => {
          if (response.data.code == 999) {
            this.isBusy = false
            this.errorresponse = response.data
          } else {

            this.more = false
            this.loadingmore = false 
            if(this.page == 0){
                this.simms = response.data.CasterSimmss
            } else this.simms.push(...response.data.CasterSimmss)

            this.displaysimms = this.simms

            this.updatePaging()

            this.total = response.data.Total
            if(this.total < this.limit){
                this.more = false
                this.nosort = false
            } this.nosort = true

            if(this.total != 0 && this.stext != ''){
                this.$emit('fetchresponse',this.stext)
            }
            
            if(this.total != 0 && this.stext != ''){
                this.$emit('fetchresponse',this.stext)
            }

            this.simmslist = this.displaysimms.map(obj => obj.connection_id)
            this.simmslist = JSON.stringify(this.simmslist)
            
            this.isBusy = false
          }

        })

    },

    editSimm(event) {
      this.simmdetail = event  
      this.modaltitle = 'Manage SIM'
      this.simmsel = event.value
      this.$refs['edit-simms'].show()
    },

  
    refreshData() {
   
     switch(this.simsuppliersel){
        case 0:
        this.modaltitle = 'Refresh BICS & Betterconnect Data'
        this.refreshtext = 'BICS & Betterconnect'
        break; 
        case 1:
        this.modaltitle = 'Refresh Betterconnect Data'
        this.refreshtext = 'Betterconnect'
        break;
        case 2:
        this.modaltitle = 'Refresh BICS Data'
        this.refreshtext = 'BICS'
        break;
     }
        this.datarefreshed = false
        this.$refs['refresh-simms'].show()
   
    },

    resyncSimData() {
        this.processing = true
        this.$refs['refresh-simms'].hide()
        this.modaltitle = 'Refreshing Data'
        this.$refs['stage-messages'].show()  
        axios.get('/api/castersimmsrefresh', {
            headers: { "Authorization": "Bearer " + this.token },
            params: {
            userid: this.userid,
            token: this.token,
            reseller: this.resellersel,
            supplier: this.simsuppliersel,
            updateusage: false,
            datestart: this.datestart,
            dateend: this.dateend
            }
        })
        .then((response) => {
            this.processing = false  
          if (response.data.code == 999) {
            alert('exception')
            //this.errorresponse = response.data
          } else {
            this.stagemessages = response.data.messages
            
          }


        })
    },

    refreshTable(){
        this.$refs['stage-messages'].hide()  
        this.fetchSimms()

    },

            showBases() {
                this.showstock = false
                this.dealersel = 0
                this.companysel = 0
                if(this.showbases == true){
                this.fields = this.fieldsbases
                } else this.fields = this.fieldslist
                this.fetchSimms()
                },

            showStock() {
            this.showbases = false
            this.companysel = 0
            if (this.showstock) { this.fields = this.fieldsstock } else {
                if (this.simsuppliersel == 2) { this.fields = this.fieldslist }
                if (this.simsuppliersel == 1) { this.fields = this.fieldslistbc }
            }
            this.fetchSimms()
            },

          
            setStatus(){
                this.page = 0
                this.fetchSimms()
            },

           updatePaging(){
                if(this.page == 0){
                    this.rstart = 1
                    this.rend = this.limit
                } else {
                this.rend = ((this.page + 1) * this.limit)
                if(this.rend > this.total){this.rend = this.total}
                } 
            },

            addTableEventList(){
                const tableScrollBody = this.$refs["simms-table"].$el
                tableScrollBody.addEventListener("scroll", this.onScroll)
            },

            removeTableEventList(){
                const tableScrollBody = this.$refs["simms-table"].$el
                tableScrollBody.removeEventListener("scroll", this.onScroll)
            },

            readdTableEventList(){
                this.$nextTick(() => {    
                    this.removeTableEventList()
                    this.addTableEventList()
                })    
            },

            showMore(){
                this.page++
                this.more = false
                this.loadingmore = true
                this.fetchSimms()
            },

            onScroll(event) {
                if (
                    event.target.scrollTop + event.target.clientHeight >=
                    event.target.scrollHeight - 200
                ) {
                    if (!this.isBusy) {
                        if(this.total > this.limit){
                            this.more = true
                        } else this.removeTableEventList()
                    }
                }
            },

            // handle column sorting events to only call API when there is more results
            onSortChanged(field,event){
              
               if(event.sortable){
                  if(this.nosort){ 
                        this.orderby = field
                        this.sortdesc = this.$refs['simms-table'].localSortDesc !== true
                        this.fetchSimms()
                        }
                    }
            },

            setDefaultSort(){
                if(this.resellerid > 0){this.orderby = 'dealer'}
                this.fetchSimms()
            },

            resetSearch(){
                this.$store.commit('setsearchtext','')
                this.searchtext = ''
                this.fetchSimms()
                this.statussel = 0
                this.showstock = 0
                this.showbases = 0
                this.$emit('clearstext')
            },

            updateModal() {
                this.$refs['edit-simms'].hide()
                this.fetchSimms()
            },

          
        }	
    };

</script>