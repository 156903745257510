<template>
  <div>
    <b-container class="mainpagecont">

      <b-row class="mt-1">

        <b-col class="col-8 mt-3 p-2">
          <h3>Reference Station<br /><span style="font-size:0.7em;color:rgba(255,255,255,0.7)">{{ refstation.text
              }}</span></h3>
        </b-col>
        <b-col class="col-4 text-right mt-3 p-2">
          <b-button variant="outline-success" @click="$router.go(-1)">BACK</b-button>
        </b-col>
      </b-row>

      <b-row class="pb-5">

        <b-col class="col-12 col-md-6 mt-1 p-2">
          <CasterReferenceStationDetails :refstation="refstation" />
        </b-col>

        <b-col class="col-12 col-md-6 mt-1 p-2">
          <CasterShowLeafletBase v-if="showmap" :base="mappoint" />
        </b-col>

        <!-- <b-col class="col-12 mt-1 p-2" v-if="refstation.type == 4 && roleid == 1">
                <CasterSimGeo
                    :endpoint="refstation.endpoint"
                    :reseller="resellersel" 
                    />
        </b-col> -->

        <b-col class="col-12 mt-1 p-2" v-if="refstation.value > 0">
          <CasterReferenceStationChartCard :baseid="refstation.value" />
        </b-col>

        <b-col class="col-12 col-sm-6 mt-1 p-2">
          <CasterReferenceStationSkyPlot v-if="showSatComps()" :base="refstation"
            :border="true" v-on:skyplotFullscreen="skyplotExpand" v-on:satStore="updateSatStore"
            v-on:height="updateSkyplotHeight" />
        </b-col>

        <b-col class="col-12 col-sm-6 mt-1 p-2" v-if="showSatComps()">
          <CasterReferenceStationSatTable :satStore="satStore" :height="skyplotHeight" />
        </b-col>

        <b-col class="col-12 mt-1 p-2">
          <CasterBasestationLogEventsCard :baseid="id" />
        </b-col>

      </b-row>
    </b-container>

    <b-modal ref="skyplot-fullscreen" size="lg" title="fullscreen" hide-header hide-footer centered
      content-class="darkmodal">
      <b-row style="min-height:300px">
        <b-col class="col-12">
          <CasterReferenceStationSkyPlot v-if="showSatComps()" :base="refstation"
            :border="false" v-on:skyplotFullscreen="skyplotContract" />
        </b-col>
      </b-row>
    </b-modal>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name" />
  </div>
</template>

<script>
import CasterReferenceStationDetails from './CasterReferenceStationDetails'
import CasterShowLeafletBase from './CasterShowLeafletBase'
import CasterReferenceStationChartCard from './CasterReferenceStationChartCard'
import CasterBasestationLogEventsCard from './CasterBasestationLogEventsCard'
import CasterReferenceStationSkyPlot from './CasterReferenceStationSkyPlot.vue'
import CasterReferenceStationSatTable from './CasterReferenceStationSatTable.vue'
import ErrorLogging from './ErrorLogging'
import axios from 'axios'

export default {
  name: 'CasterReferenceStation',
  components: {
    CasterReferenceStationDetails,
    CasterShowLeafletBase,
    CasterReferenceStationChartCard,
    CasterReferenceStationSkyPlot,
    CasterBasestationLogEventsCard,
    CasterReferenceStationSatTable,
    ErrorLogging,

  },
  data() {
    return {

      id: 0,
      refstation: {},
      basedata: [],
      mappoints: [],
      mappoint: {
        id: 0,
        latitude: 0,
        longitude: 0,
        color: '',
        name: '',
        status: 0
      },
      duration: '',
      timeout: false,
      token: '',
      satStore: {},
      satCount:0,
      display: false,
      showmap: false,
      boxh: 0,
      boxw: 0,
      errorresponse: {},
      skyplotHeight: "",
      roleid: 0,
      resellersel: 0
    }


  },

  mounted() {
    this.$store.commit('setbasesdatapoll', 0)
  },

  created() {
    this.token = this.$store.getters.token
    this.roleid = this.$store.getters.user.role
    this.resellersel = this.$store.getters.resellerid
    this.$store.commit('setbasesdatapoll', 0)
    this.id = this.$route.params.id
    this.getBase()
  },

  beforeDestroy() {
    clearTimeout(this.timeout)
  },

  methods: {

    getBase() {

      axios.get('/api/basestationstest', {
        headers: { "Authorization": "Bearer " + this.token },
        params: {
          userid: this.$store.getters.user.id,
          token: this.$store.getters.user.token,
          id: this.id
        }
      })
        .then((response) => {

          if (response.data.code == 999) {
            this.errorresponse = response.data
          } else {

            this.refstation = response.data.basestations[0]
            this.basedata = response.data.rtcm3

            this.createMapPoints()
            this.timeout = setTimeout(this.getBase, 3000)
          }

        });

    },



    createMapPoints() {

      this.mappoint.id = 1
      this.mappoint.name = this.refstation.text
      this.mappoint.latitude = this.refstation.latitude
      this.mappoint.longitude = this.refstation.longitude
      this.mappoint.color = this.refstation.statuscolor
      this.mappoint.status = this.refstation.status
      // Delay fiddle to give time for left panel to load to set height for map panel
      if (!this.showmap) {
        setTimeout(this.enableMap, 150)
      }

    },

    enableMap() {
      this.showmap = true
    },

    updateSatStore(data,count) {
      this.satStore = data;
      this.satCount = count;
    },

    updateSkyplotHeight(data) {
      this.skyplotHeight = data;
    },

    skyplotExpand() {
      this.$refs['skyplot-fullscreen'].show()
    },

    skyplotContract() {
      this.$refs['skyplot-fullscreen'].hide()
    },

    showSatComps() {
      if(this.refstation.type === 4 && this.refstation.status === 1 && this.refstation.gsats + this.refstation.rsats > 0 ){
        return true
      }
      return false
    }
  }
}
</script>