<template>
<div>

<b-container v-if="display"> 

    <b-row class="mt-1 pb-3" style="border-bottom:1px solid rgba(255,255,255,0.5)">
    <div class="stageselectmain">
        <div class="stageselectbox" style="width:22%"><div class="stageSelect" @click="setPage(1)" v-bind:class="userclass "><b-icon-person /></div>details</div>
        <div class="stageselectline" v-bind:class="line1class"></div>
        <div class="stageselectbox" style="width:22%"><div class="stageSelect" @click="setPage(2)" v-bind:class="editclass"><b-icon-pencil /></div>edit</div>
        <div class="stageselectline" v-bind:class="line2class"></div>
         <div class="stageselectbox" style="width:22%"><div class="stageSelect" @click="setPage(3)" v-bind:class="createclass"><b-icon-check /></div>update</div>
    
    </div>
                
    </b-row>

    <b-container class="addrowpage" v-if="page == 1">
    <b-row class="mt-5">
                <b-col class="col-12 col-md-5 mt-1">Name</b-col>
                <b-col class="col-12 col-md-7 mt-1">{{ user.fname }} {{ user.lname }}</b-col>
                <b-col class="col-12 col-md-5 mt-3">Phone</b-col>
                <b-col class="col-12 col-md-7 mt-3">{{ user.mobile }}</b-col>
                <b-col class="col-12 col-md-5 mt-3">Email</b-col>
                <b-col class="col-12 col-md-7 mt-3">{{ user.email }}</b-col>
                
                <b-col class="col-12 col-md-5 mt-3">Role</b-col>
                <b-col class="col-12 col-md-7 mt-3">{{ user.roletitle }}</b-col>
                <b-col class="col-12 col-md-7 offset-md5 mt-3" v-if="roleid == 10">{{ user.dealertitle }}</b-col>
                <b-col class="col-12 col-md-7 offset-md-5 mt-3" v-if="roleid == 20">{{ user.companytitle }}</b-col>
                <b-col class="col-12 col-md-6 offset-md-5" v-if="user.readonly == 1">Read Only Access</b-col>
    </b-row>
    <b-row>    
                <b-col class="col-12 col-md-5 mt-3">Version</b-col>
                <b-col class="col-12 col-md-7 mt-3">v4.88</b-col>
    </b-row> 
   
    <b-row class="mt-5">
        <b-col class="col-12">
            <b-button variant="outline-success" block ref="Next" @click="page=2">Next</b-button>
        </b-col>  
    </b-row>   

    </b-container>

    <b-container class="addrowpage" v-if="page == 2">

    <b-row>
      
		<b-col class="col-12 mt-3">
                <label>First Name</label>
				<b-form-input 
                    type="search" 
                    placeholder="first name" 
                    autocomplete="off"
                    v-model="user.fname"
                    name="user.fname"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('user.fname') }"  
                    class="darkinput"
                    @input = "validateField('user.fname')"
                    />
                     <span style="color:red;">{{ errors.first('user.fname') }}</span>
		</b-col>

		<b-col class="col-12 mt-3">
                <label>Last Name</label>
				<b-form-input 
                    type="search"
                    placeholder="last name"
                    autocomplete="off" 
                    v-model="user.lname" 
                    name="user.lname"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('user.lname') }"
                    class="darkinput"
                    @input = "validateField('user.lname')"  
                    />
                     <span style="color:red;">{{ errors.first('user.lname') }}</span>
		</b-col>

    
        <b-col class="col-12 mt-3">
                <label>Email</label>
				<b-form-input 
                    type="search"
                    placeholder="login email"
                    autocomplete="off" 
                    v-model="user.email" 
                    name="user.email"
                    v-validate="'required|customRule:'+emailerror"
					:class="{'input': true, 'is-danger': errors.has('user.email') }"
                    class="darkinput"
                    @input = "validateField('user.email')"
                    @change = "validateField('user.email')"
                    />
                    <span style="color:red;">{{ errors.first('user.email') }}</span>
		</b-col>

        <b-col class="col-12 mt-3">
                <label>Phone</label>
				<b-form-input 
                    type="search"
                    placeholder="mobile or telephone"
                    autocomplete="off" 
                    v-model="user.mobile" 
                    class="darkinput"  
                    />
		</b-col>

        <b-col class="col-12 mt-3" v-if="user.value !== undefined">
                <label>Change Password</label>
				<b-form-input 
                    type="search"
                    placeholder="new password"
                    autocomplete="off" 
                    v-model="user.changepassword" 
                    class="darkinput"  
                    />
		</b-col>
		
</b-row>
   
     <b-row class="mt-5 mb-3">
         <b-col class="col-12">
            <b-button variant="outline-success" block  @click="validateUser()">Next</b-button>
         </b-col>
    </b-row> 

 </b-container>


 <b-container class="addrowpage" v-if="page == 3">

    <b-row class="mt-3">
                <b-col class="col-12 col-md-5 mt-2">Name</b-col>
                <b-col class="col-12 col-md-7 mt-1">{{ user.fname }} {{ user.lname }}</b-col>
                <b-col class="col-12 col-md-5 mt-md-3">Phone</b-col>
                <b-col class="col-12 col-md-7 mt-md-3">{{ user.mobile }}</b-col>
                <b-col class="col-12 col-md-5 mt-md-3">Email</b-col>
                <b-col class="col-12 col-md-7 mt-md-3">{{ user.email }}</b-col>
                <b-col class="col-12 col-md-5 mt-md-3">Password</b-col>
                <b-col class="col-12 col-md-7 mt-md-3">{{ user.password }}</b-col>
                <b-col class="col-12 col-md-5 mt-3">Type</b-col>
                <b-col class="col-12 col-md-7 mt-3">{{ user.roletitle }}</b-col>
                <b-col class="col-12 col-md-7 offset-md-5 mt-1" v-if="roleid == 10">{{ user.dealertitle }}</b-col>
                <b-col class="col-12 col-md-7 offset-md-5 mt-1" v-if="roleid == 20">{{ user.companytitle }}</b-col>
                <b-col class="col-12 col-md-6 offset-md-5" v-if="user.readonly == 1">Read Only Access</b-col>
    </b-row> 

    <b-row class="mt-3">
        <b-col class="col-12" v-if="!sending && !sent">
            <b-button variant="danger" block disabled>Email not sent</b-button>
        </b-col>
    </b-row>

    <b-row class="mt-5">
     <b-col class="col-12">
             <b-button v-if="!updating" variant="outline-success" block  @click="updateUser()">Update profile</b-button>
             <b-button v-else variant="outline-success" block  @click="updatemodal()">Finish</b-button>
        </b-col>  
    </b-row>

 
</b-container>   

</b-container>

<SendEmail 
        :sendmail="sendmail"
        :emailid="emailid" 
        :emailto="emailto"
        :pw="user.password" 
        :showdiag="showdiag"
        v-on:emailsent="emailSendStatus"
        />

<ErrorLogging 
    :errorresponse="errorresponse" 
    :component="this.$options.name"
    />        
		
</div> 
</template>


<script>
    import SendEmail from './SendEmail'
    import ErrorLogging from './ErrorLogging'
    import { Validator } from 'vee-validate'
    import './customRule'
    import axios from 'axios'
	export default {
        name:'CasterUserProfile',
        components:{
            SendEmail,
            ErrorLogging
        },
        data(){
		return {
                roleid:0,
                token:'',
                user:{},
                password:'',
                username:'',
                version:'-',
                devversion:'-',
                dev:0,
                currentemail:'',
                emailerror:false,
                page:1,
                userclass:'stagecomp',
                editclass:'stagecomp',
                createclass:'stagecomp',
                line1class:'stageselectlinecomp',
                line2class:'stageselectlinecomp',
                sendmail:false,
                sent:99,
                emailto:'',
                emailid:10, // User login details email template
                showdiag:false,
                sending:false,
                display:false,
                updating:false,
                errorresponse:{},
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.roleid = this.$store.getters.roleid
            this.version = this.$store.getters.version
            this.devversion = this.$store.getters.devversion
            this.dev = 1 //this.$store.getters.dev
            this.fetchUser()
        },

         mounted() {
            Validator.localize('en',{
                    messages: {
                    required: '** required.',
                    },
            })   
        },

      
		methods: {

            fetchUser(){
                axios.get('/api/casterusers',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        id:this.$store.getters.user.id,
                        all:1
                    }
                })
				.then((response) => {
                    this.user = response.data.users[0]
                    this.currentemail = this.user.email
                    this.user.changepassword = ''
                    this.display = true
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
           },

           setPage(id){
               this.page = id
           },

           validateUser(){
               this.$validator.validateAll().then(result => {
                   if(result){
                       if(this.user.changepassword !== ''){this.user.password = this.user.changepassword}
                       this.page = 3
                       }
               })
           },

            validateField(field){
               setTimeout(() => this.validateDebounced(field), 300)
            },

            validateDebounced(field){
                this.$validator.validate(field)
                if(field == 'user.email'){this.emailCheck()}
           },

            emailCheck(){
                axios.get('/api/checkuserexists',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         email:this.user.email
                    }
                })
				.then((response) => {
                  
                      if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {
                  
                    if(response.data.exists == true){
                        this.emailerror = true
                        } else {
                            this.emailerror = false //this.$emit('dealer',this.dealer)
                            }
                      } 
                      
                    });    
           },

          updateUser(){

                 if(this.user.email !== this.currentemail){
                        if(!this.emailCheck()){return}
                    }
                this.updating = true    
                this.emailto = this.user.email    

                this.$validator.validateAll().then(result => {
                    if (result){
               
                        axios({
                                method: 'put',
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/casteruserprofile',
                                data: {
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,
                                    userdata:this.user
                                    }
                                })
                            .then((response)=> {

                               
                            if(response.data.code == 999){
                                this.errorresponse = response.data.message
                            } else 
                                if(this.currentemail != this.user.email || this.user.changepassword != ''){this.sendEmail()}
                                else this.sent = true
                                
                            
                        });

                    }
                });        
                
          }, 
          
          sendEmail(){
                this.sending = true
                this.sendmail = !this.sendmail // trigger to SendEmail
            },

        emailSendStatus(value){
                this.sending = false
                this.sent = value
            }, 
                    
         updatemodal(){
              this.$emit('updatemodal')
         },

         closemodal(){
              this.$emit('closemodal')
         },

		}	   
	
	};

</script>