<template>
<div>
<b-row class="mt-1 pb-3" style="border-bottom:1px solid rgba(255,255,255,0.5)">
    <div class="stageselectmain">
        <div class="stageselectbox"><div class="stageSelect" @click="setPage(1)" v-bind:class="companyclass "><b-icon-person /></div>company</div>
        <div class="stageselectline" v-bind:class="line1class"></div>
        <div class="stageselectbox"><div class="stageSelect" @click="setPage(2)" v-bind:class="machineclass"><b-icon-truck /></div>machinery</div>
        <div class="stageselectline" v-bind:class="line2class"></div>
        <div class="stageselectbox"><div class="stageSelect" @click="setPage(3)" v-bind:class="subclass"><b-icon-calendar-month /></div>subscription</div>
        <div class="stageselectline" v-bind:class="line3class"></div>
        <div class="stageselectbox"><div class="stageSelect" @click="setPage(4)" v-bind:class="createclass">
            <b-icon-check v-if="!adminlock" />
            <b-icon-lock v-else />
        </div>
        <span v-if="roversel == 0">create</span><span v-else>update</span></div>
    
    </div>
                
</b-row>

<b-container class="addrowpage" v-if="page == 1">

<span v-if="display">

<b-row class="mt-3 mb-5" v-if="!nosubstock">

    <b-col class="col-12">

    <b-row v-if="roleid == 1">

            <b-col class="col-12 col-sm-6 mt-3">
            <label>Reseller</label>
            <b-form-select
                        v-model="resellersel"
                        :options="resellers"
                        v-on:change="changeReseller()"
                        class="darkinput"
                        :disabled ="resellerlock"
                        />
            </b-col>

            <b-col class="col-sm-5 offset-sm-1 mt-3">

            <b-row class="mt-3" >
                <b-col class="col-9">{{ reseller.text}}</b-col>
                <b-col class="col-12">{{ reseller.address1}}</b-col>
                <b-col class="col-12" v-if="reseller.address2">{{ reseller.address2}}</b-col>
                <b-col class="col-12" v-if="reseller.address3">{{ reseller.address3}}</b-col>
                <b-col class="col-12">{{ reseller.towncity}}</b-col>
                <b-col class="col-12">{{ reseller.county}} {{ reseller.postcode}}</b-col>
            
            </b-row>     

        </b-col>

    </b-row> 

    <b-row v-if="roleid != 10">
        <b-col class="col-12 col-sm-6 mt-3">
        <label>Dealer</label>
        <b-form-select
                    v-model="dealersel"
                    :options="dealers"
                    v-on:change="changeDealer()"
                    class="darkinput"
                    :disabled ="dealersellock"
                    />
    </b-col>

     <b-col class="col-12 col-sm-5 offset-sm-1 mt-3">
    
        <b-row class="mt-1" >
            <b-col class="col-9"></b-col>
            <b-col class="col-12">{{ dealer.address1}}</b-col>
            <b-col class="col-12" v-if="dealer.address2">{{ dealer.address2}}</b-col>
            <b-col class="col-12" v-if="dealer.address3">{{ dealer.address3}}</b-col>
            <b-col class="col-12">{{ dealer.towncity}}</b-col>
            <b-col class="col-12">{{ dealer.county}} {{ dealer.postcode}}</b-col>
        
        </b-row> 

     </b-col>

    </b-row> 

    <b-row>

        <b-col class="col-12 col-sm-6 mt-3">
            <label>Company</label>
            <b-form-select
                        v-model="companysel"
                        :options="companies"
                        v-on:change="setCompany()"
                        class="darkinput"
                        
                        />
        </b-col>


        <b-col class="col-sm-5 offset-sm-1 mt-3">

            <b-row class="mt-1" >
                <b-col class="col-9">{{ company.text}}</b-col>
                <b-col class="col-12">{{ company.address1}}</b-col>
                <b-col class="col-12" v-if="company.address2">{{ company.address2}}</b-col>
                <b-col class="col-12" v-if="company.address3">{{ company.address3}}</b-col>
                <b-col class="col-12">{{ company.towncity}}</b-col>
                <b-col class="col-12">{{ company.county}} {{ company.postcode}}</b-col>
            
            </b-row> 

        </b-col>

    </b-row>
    </b-col> 

</b-row> 

</span>

<b-row class="mt-5" v-if="nosubstock">
    <b-col class="col-12 mt-3">
        <b-button variant="danger" block disabled>WARNING : NO SUBSCRIPTIONS AVAILABLE IN STOCK</b-button>
    </b-col>
</b-row>


<b-row class="mt-5">
    <b-col class="col-12" v-if="companysel != 0 && !nosubstock">
        <b-button variant="outline-success" block ref="next" size="lg" @click="setPage(2)">Next</b-button>
     </b-col>  
</b-row>     



</b-container>

<b-container class="addrowpage pt-3" v-if="page == 2">   
        <span v-if="!editmachine">

        <b-row >
            <b-col class="col-12 text-right mt-1">
                <b-button variant="outline-success" size="sm" @click="addMachine()" :disabled ="adminlock" style="margin-right:5px" > 
                <b-icon-plus-circle class="sidemenuiconsel"  /> add machinery
                </b-button>
            </b-col> 
        </b-row>

        <b-row class="mt-3">
            <b-col class="col-12 col-sm-4 mb-1" v-for="(machine) in machines" :key="machine.value" >
                <b-button block variant="success" @click="setMachine(machine)" v-if="machinesel == machine.value">
                    {{ machine.model_title }} {{ machine.make_title }}<br/>{{ machine.regnum }}
                </b-button> 
                <b-button block variant="outline-success" @click="setMachine(machine)" v-else>
                    {{ machine.model_title }} {{ machine.make_title }}<br/>{{ machine.regnum }}
                </b-button>   

                <b-button variant="outline-success" size="sm" @click="editMachine(machine)" :disabled ="adminlock" style="margin-top:10px"> 
                <b-icon-pencil class="sidemenuiconsel"  /> edit
                </b-button> 
            </b-col>
        </b-row>


         <b-row class="mt-5">
            <b-col class="col-12 mt-4">
                <b-button variant="outline-success"  block ref="next" size="lg" @click="setPage(3)">Next</b-button>
            </b-col>
        </b-row>   

        </span>

        <span v-else>

            <b-row>
            <b-col class="col-12 col-md-8 offset-md-2">    

            <CasterCompanyMachineEditOld
            :machinedata = "machineselected"
            :company ="companysel"
            :newmachine = "newmachine"
            v-on:closemodal="closemodal()"
            v-on:updatemachine="updatemachine"
            v-on:returnmachine="updatemachine2"
            />

            </b-col>
            </b-row>

        </span>    
      
</b-container>

<b-container class="addrowpage" v-if="page == 3">

    <b-row>
        <b-col class="col-12 col-sm-10 offset-sm-1">
            <CasterSubscriptionManage
                :sdata="sub"
                :reseller="resellersel"
                :dealer="dealersel"
                :company="companysel"
                v-on:updatesub=updateSub
                />
            </b-col>
    </b-row>    

</b-container>

<b-container class="addrowpage" v-if="page == 4">

    <b-row class="mt-5">
        <b-col class="col-6"><h5>{{ sub.username }}</h5></b-col>
        <b-col class="col-6 text-right"><h5>{{ machine[0].make_title }} {{ machine[0].model_title }} {{ machine[0].regnum }}</h5></b-col>
    </b-row>    

    <b-row class="mt-3">
        <b-col class="col-12 col-sm-4">
            <b-row>
                <b-col class="col-12"><h5>Company</h5></b-col>
                <b-col class="col-12">{{ company.text}}</b-col>
                <b-col class="col-12">{{ company.address1}}</b-col>
                <b-col class="col-12" v-if="company.address2">{{ company.address2}}</b-col>
                <b-col class="col-12" v-if="company.address3">{{ company.address3}}</b-col>
                <b-col class="col-12">{{ company.towncity}}</b-col>
                <b-col class="col-12">{{ company.postcode}}</b-col>
            </b-row>
        </b-col>

   
       <b-col class="col-12 col-sm-8">
            <b-row>
                <b-col class="col-12"><h5>Subscription</h5></b-col> 
                <b-col class="col-12 col-sm-4">Type</b-col>
                <b-col class="col-12 col-sm-8">{{ sub.title }}</b-col>
                <b-col class="col-12 col-sm-4">Username</b-col>
                <b-col class="col-12 col-sm-8">{{ sub.username }}</b-col>
                <b-col class="col-12 col-sm-4">Password</b-col>
                <b-col class="col-12 col-sm-8">{{ sub.password }}</b-col>
                <b-col class="col-12 col-sm-4">Connection IP/URL</b-col>
                <b-col class="col-12 col-sm-8">{{ sub.connection }}</b-col>
                <b-col class="col-12 col-sm-4">Connection Port</b-col>
                <b-col class="col-12 col-sm-8">{{ sub.port }}</b-col>
                <b-col class="col-12 col-sm-4">SIM</b-col>
                <b-col class="col-12 col-sm-8">{{ sub.simmtext }}</b-col>      
                <b-col class="col-12 col-sm-4">Activation</b-col>
                <b-col class="col-12 col-sm-8">{{ sub.start }}</b-col>  
                <b-col class="col-12 col-sm-4">Expires</b-col>
                <b-col class="col-12 col-sm-8">{{ sub.end }}</b-col> 
                
            </b-row>

             <b-row>
                <b-col class="col-12 col-sm-4">Purchase Order</b-col>
                <b-col class="col-12 col-sm-8">{{ sub.purchase_order }}</b-col>
            </b-row>    
       </b-col>

    </b-row>

     <b-row class="mt-3">
                <b-col class="col-4 mt-1">
                    <label style="margin-right:10px;">{{ emailtypetext }} </label>
                
                    <b-form-checkbox
                    inline
                    v-model="sendwelcomeemail"
                    size="lg"
                    value=1
                    unchecked-value=0
                    :disabled="adminlock"
                    />    
                </b-col>
                <b-col class="col-8 mt-1" v-if="sendwelcomeemail == 1">
                    <b-form-input 
                    type="search"
                    placeholder="email address"
                    autocomplete="off" 
                    v-model="emailto" 
                    class="darkinput"
                    :disabled="adminlock"  
                    />
                </b-col>
    </b-row> 
    
    <b-row class="mt-5">
     <b-col class="col-12">
            <b-button variant="outline-success" block size="lg" @click="createRover()" :disabled="adminlock">
                <span v-if="roversel == 0">Create Rover</span>
                <span v-else>Update Rover</span>
                </b-button>
        </b-col>  
    </b-row>

<b-modal ref="create-rover" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-5">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
   </b-row> 

   <b-row>
         <b-col class="col-12 mt-5">
        <b-button variant="outline-success" @click.stop="finishRover()" block>Finish</b-button>
       </b-col>   
   </b-row>          

</b-modal>    

</b-container>

 <!-- Welcome email -->
 <SendEmail
        :sendmail="sendmail"
        :emailid="emailid"
        :emailto="emailto" 
        :subid="addedsub"
        :showdiag=false
        v-on:emailsent="emailSendStatus"
        />

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
  
</div> 
</template>

<style scoped>
.stagecomp:hover{
    cursor:pointer;
}
</style>


<script>
    import CasterSubscriptionManage from './CasterSubscriptionManage.vue'
    import CasterCompanyMachineEditOld from './CasterCompanyMachineEditOld'
    import SendEmail from './SendEmail'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
	export default {
        props:['roverid'],
        name:'CasterRoverAdd',
        components:{
            CasterSubscriptionManage,
            CasterCompanyMachineEditOld,
            SendEmail,
            ErrorLogging
        },
        data(){
        return {
                edit:false,
                token:'',
                roleid:0,
                resellers:[],
                dealers:[{text:'Select',value:0}],
                companies:[{text:'Select',value:0}],
                machines:[],
                machineselected:{},
                machinesel:0,
                tempmid:8999,
                machine:{
                    make_title:'-',
                    model_title:'-',
                    regnum:'-'
                 },
                newmachine:false,
                editmachine:false,
                reseller:{},
                dealer:{},
                company:{},
                newcompany:false,
                resellersel:0,
                dealersel:0,
                companysel:0,
                subsel:0,
                sub:{},
                sim:{},
                user:{},
                sendwelcomeemail:1,
                emailtypetext:'',
                sendmail:false,
                sent:99,
                emailto:'',
                emailid:0, // Welcome - 1 , NTRIP Details - 12
                showdiag:false,
                sending:false,

                nosubstock:false,
                resellerlock:true,
                dealersellock:true,
                companysellock:false,
                errorresponse:{},
               
                modaltitle:'',
                searchtext:'',
                page:1,
               
                companyclass:'stageactive',
                machineclass:'',
                subclass:'',
                createclass:'',
                line1class:'stageselectline',
                line2class:'stageselectline',
                line3class:'stageselectline',
                newrover:{},
                stagemessages:[],
                saving:false,
                addedsub:0,
                createerror:false,
                rovercreatestage:1,
                roversel:0,
                machinefilter:1,
                adminlock:false,
                display:false,
                };
                
                },
            
           

        mounted(){
            this.$store.commit('setbasesdatapoll',0)
            this.$store.commit('setroversdatapoll',0)
        },
        
        watch:{

            dealersel: function() {
                        this.fetchCompanies()
                        },

            companysel: function () {
                         if(this.companysel !== 0){this.companyclass = 'stagecomp',this.line1class = 'stageselectlinecomp'} else {this.companyclass = 'stageactive',this.line1class = 'stageselectline'}
                         if(this.roversel == 0 && this.companysel !== 0){this.checkStock()}
                         },
            machinesel: function () {
                         if(this.machinesel !== 0){
                             this.machineclass = 'stagecomp',this.line2class = 'stageselectlinecomp'
                             } else {this.machineclass = 'stageactive',this.line2class = 'stageselectline'}
                         }, 
            subsel: function () {
                         if(this.subsel !== 0){this.subclass = 'stagecomp'} else this.subclass = 'stageactive'
                        }, 
        },

        created(){
            this.token = this.$store.getters.token
            this.roleid = this.$store.getters.roleid
            this.user = this.$store.getters.user
            this.roversel = this.roverid
          
            
            // New Rover
            if(this.roversel == 0){
                this.edit = false
                this.resellersel = this.$store.getters.resellerid 
                this.dealersel = this.$store.getters.dealerid
                this.companysel = 0
                this.emailid = 1 // Welcome
                this.emailtypetext = 'Send welcome email'
                this.checkStock()
                this.getDropdowns()
            // Edit Rover     
                } else {
                    this.edit = true
                    this.sendwelcomeemail = 0
                    this.emailid = 12 // NTRIP details
                    this.emailtypetext = 'Send NTRIP details'
                    this.machinefilter = 2
                    this.waitfetchRover().then(() => {
                        this.getDropdowns()
                        })
                    }
                this.setLocks()
            },
  
       methods: {

            setLocks(){
                switch(this.roleid){
                case 1:     // Sysadmin
                this.resellerlock = false
                this.dealersellock = false
                break;
                case 5:     // Reseller Admin
                this.resellerlock = true
                this.dealersellock = false
                break;
                case 10:    //  Dealer admin
                this.resellerlock = true
                this.dealersellock = true
                break;
                }

            },

            getDropdowns(){
                this.waitfetchDropdowns().then(() => {
                    this.setReseller()
                    this.setDealer()
                    this.setCompany()
                    this.display = true
                })
            },

            updateSub(data){
                this.sub = data
                this.subsel = data.subid
                this.setPage(4)
            },

            async waitfetchRover(){
                await this.fetchRover()
            },
          
            async fetchRover(){
                return new Promise((resolve) => {

                axios.get('/api/companyroverstest',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        id:this.roversel
                        }
                    })
				.then((response) => {

                    if(response.data.code == 999){
                            this.errorresponse = response.data
                    } else {

                    this.rover = response.data.rovers[0] 
                    if(this.rover.machine.length != 0){this.machine = this.rover.machine[0]}  
                 
                    this.resellersel = this.rover.reseller
                    this.dealersel = this.rover.dealer
                    this.companysel = this.rover.company
                    this.subsel = this.rover.subid
                    this.machinesel = this.machine.value

                    this.sub.user = this.$store.getters.user.id
                    this.sub.rover = this.roversel
                    this.sub.username = this.rover.username
                    this.sub.password = this.rover.password
                    this.sub.connection = this.rover.connection
                    this.sub.port = this.rover.port
                    this.sub.years = this.rover.subscriptiondetail[0].subscription[0].years
                    this.sub.months = this.rover.subscriptiondetail[0].subscription[0].months
                    this.sub.days = this.rover.subscriptiondetail[0].subscription[0].days
                    this.sub.dealer = this.dealersel

                    // RB 4/10 Get sub detail from rover sub detail not machine sub
                    let roversub = this.rover.subscriptiondetail[0]

                    this.sub.start = roversub.startdate2
                    this.sub.end = roversub.enddate2
                    this.sub.id =  roversub.value
                    this.sub.subid =  roversub.stocksub
                    this.sub.value =  roversub.value
                    this.sub.nosimm =  roversub.nosimm
                    this.sub.purchase_order = roversub.purchase_order

                   // if(this.machine.sub.length != 0){
                        // this.sub.start = this.machine.sub[0].startdate
                        // this.sub.end = this.machine.sub[0].enddate2
                        // this.sub.id =  this.machine.sub[0].value
                        // this.sub.subid =  this.machine.sub[0].stocksub
                        // this.sub.value =  this.machine.sub[0].value
                        // this.sub.nosimm =  this.machine.sub[0].nosimm
                        //this.sub.purchase_order = this.machine.sub[0].purchase_order
                   // }    

                    if(this.rover.needs_gga == 'Yes'){this.sub.needs_gga = 1} else this.sub.needs_gga = 0
                    if(this.rover.disconnect_onchangebase =='Yes'){this.sub.disconnect_onchangebase= 1} else this.sub.disconnect_onchangebase = 0
                   
                    if(this.sub.nosimm == 0){
                        this.sub.simm = this.rover.simm[0].value
                        this.sub.simmstatus = this.rover.simm[0].statusid
                        this.sub.simmtext =  this.rover.simm[0].text
                    }

                    this.sub.title = this.rover.subscriptiondetail[0].subscription[0].text
                    this.sub.type = this.rover.subscriptiondetail[0].subscription[0].value

                    this.emailto = this.rover.companydetail.email
                    resolve()
                    
                    }
                    
                    });

                }) // end resolve
           },

            createRover(){
                 
                this.stagemessages = [] 
                if(this.newrover.action == 1){this.modaltitle="Creating Rover"} else this.modaltitle="Updating Rover"
                 this.$refs['create-rover'].show() 

                this.saving = true
                if(this.resellersel == 11){this.newrover.countryid = 2} else this.newrover.countryid = 1
                this.newrover.roverid = this.sub.rover
                this.newrover.resellerid = this.resellersel
                this.newrover.companyid = this.companysel
                this.newrover.dealerid = this.sub.dealer
                this.newrover.machineid = this.machinesel
                this.newrover.username = this.sub.username
                this.newrover.password = this.sub.password
                this.newrover.connection = this.sub.connection
                this.newrover.port = this.sub.port
                this.newrover.needs_gga = this.sub.needs_gga
                this.newrover.disconnect_onchangebase = this.sub.disconnect_onchangebase
                this.newrover.purchase_order = this.sub.purchase_order
                this.newrover.simm = this.sub.simm
                this.newrover.nosimm = this.sub.nosimm
                this.newrover.subid = this.sub.subid
                this.newrover.subtype = this.sub.type
                this.newrover.substart = this.sub.start
                this.newrover.subend = this.sub.end
                this.newrover.user = this.sub.user
                this.newrover.activatenow = 1
                if(this.roversel == 0){this.newrover.action = 1} else this.newrover.action = 2

                 axios({
                    method: 'post',    
                    headers: {"Authorization" : "Bearer " + this.token},
                    url: '/api/roveraddrod',
                    data: {
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            roverdata:this.newrover,
                            machines:this.machines,
                            country:1,
                            reseller:this.resellersel,
                            dealer:this.dealersel,
                            edit:this.edit
                            
                        }
                    })
                    .then((response)=> {
                            if(response.data.code == 999){
                                    this.errorresponse = response.data
                                } else {
                                    this.addedsub = response.data.sub // Sub ID needed for email 
                                    this.stagemessages = response.data.messages
                                    if(response.data.error == false){
                                        if(this.sendwelcomeemail == 1){this.sendEmail()}
                                        }
                                }
                            });	
            },  

            sendEmail(){
                this.sendmail = !this.sendmail // trigger to SendEmail
            },

            emailSendStatus(data){
                    this.stagemessages.push(...data.messages)
            }, 

          
            finishRover(){
                    this.$refs['create-rover'].hide()
                    this.$emit('updatemodal')
            },


            async waitfetchDropdowns(){
                if(this.edit){ // edit rover
                    if(this.roleid == 1){await this.fetchResellers()} // System admins
                    if(this.roleid == 1 || this.roleid == 5){await this.fetchDealers()} // System admins + Reseller admins
                    await this.fetchCompanies()
                } else { // new rover
                    if(this.roleid == 5){await this.fetchDealers()} // Reseller admins
                    if(this.roleid == 10){await this.fetchCompanies()} // Dealer admins
                }
                
            },
          
           fetchResellers(){

           return new Promise((resolve) => {

				axios.get('/api/casterbusinesses',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token
                    }
                })
				.then((response) => {

                    if(response.data.code == 999){
                                    this.errorresponse = response.data
                                } else {
                                    this.resellers = []
                                    this.resellers.push({text:'Select',value:0})
                                    this.resellers.push(...response.data.CasterBusiness)
                                    resolve()
                                    //if(this.resellersel != 0){this.fetchDealers()}
                                    }
                    });

                });     
			}, 

            async fetchDealers(){
                return new Promise((resolve) => {

                this.dealers = []
				axios.get('/api/casterdealers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.resellersel,
                        list:1,
                        filterbyreseller:1
                     }
                })
				.then((response) => {
                        if(response.data.code == 999){
                                this.errorresponse = response.data
                                } else {
                                    this.dealers.push({value:0,text:'Select'})
                                    this.dealers.push(...response.data.CasterDealers)
                                    resolve()
                                    //if(this.dealersel != 0){this.fetchCompanies()}
                                }
                    });

                });    
			},

            async fetchCompanies(){
                return new Promise((resolve) => {
                    axios.get('/api/castercompanies',{ 
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         stext:this.searchtext,
                         reseller:this.resellersel,
                         dealer:this.dealersel,
                         nousers:1
                     }
                })
				.then((response) => {
                        if(response.data.code == 999){
                                    this.errorresponse = response.data
                                } else {
                                    this.companies = []
                                    this.companies.push({value:0,text:'Select'})
                                    this.companies.push(...response.data.CasterCompanies)
                                    resolve()
                                    //this.setCompany()
                                }
                         });        
                    });
			},

             changeReseller(){
                if(this.resellersel !== 0){
                    this.dealersel = 0
                    this.companysel = 0
                    this.dealer = ''
                    this.company = ''
                    this.setReseller()
                    this.fetchDealers()
                } else {
                        this.dealers = [{value:0,text:'Select'}]
                        this.dealersel = 0
                        this.companysel = 0
                        }
                this.companies = [{value:0,text:'Select'}]
            },

            setReseller(){
                let reseller = this.resellers.filter(reseller => reseller.value == this.resellersel)
                this.reseller = reseller[0]
            },

            changeDealer(){
                if(this.dealersel !== 0){
                    this.companysel = 0
                    this.company = ''
                    this.setDealer()
                    this.fetchCompanies()
                    } else {
                        this.companysel = 0
                        this.companies = [{value:0,text:'Select'}]
                        }
            },

            setDealer(){
                let dealer = this.dealers.filter(dealer => dealer.value == this.dealersel)
                this.dealer = dealer[0]
            },

            setCompany(){
                if(this.companysel != 0){
                let company = this.companies.filter(company  => company .value == this.companysel)
                this.company  = company[0]
                this.emailto = this.company.email
                console.log(this.company)
                }
               
            },

            fetchMachines(){	
                this.machines = []
                axios.get('/api/companymachines',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        company:this.companysel,
                        id:this.machinesel,
                        filter:this.machinefilter
                        }
                })
				.then((response) => {
                    if(response.data.code == 999){
                            this.errorresponse = response.data
                            } else {
                            this.machines = response.data.machines
                            this.machine = this.machines.filter(machine=>machine.value == this.machinesel)
                            }
                    });
           },

           addMachine(){
                    this.newmachine = true
                    this.editmachine = true
            },

            editMachine(machine){
                    this.machineselected = machine
                    this.machinesel = machine.id
                    this.newmachine = false
                    this.editmachine = true
            },

            setMachine(data){
                
                
                 this.machinesel = data.value
                 let machine = this.machines.filter(machine=>machine.value == this.machinesel)
                 machine[0].dealer = this.dealersel
                 machine[0].company = this.companysel
                 machine[0].rover = this.roversel
                 this.machine[0] = machine[0]
             },

             updatemachine(value){
                   
                    this.machinesel = value
                    //this.fetchMachines()
                    this.newmachine = false
            },

            updatemachine2(machine){
                //  this.newmachine = false
                if(machine.id == 0){ // New machine so add to machines
                    machine.rover = 0
                    machine.value = ++this.tempmid
                    this.machines.push(machine)
                }

                 this.editmachine = false
                //  this.machineselected = machine
                //  this.machinesel = machine.id
            },
         
            setPage(id){
                
                switch(id){
                    case 1:
                    if(this.companysel != 0){this.page = id}    
                    break
                    case 2:
                    if(this.companysel != 0){
                        if(this.machines.length == 0){
                            this.fetchMachines()
                            }
                        this.page = id
                        }
                    break
                    case 3:
                    if(this.machinesel != 0){
                        this.page = id
                        }
                    break
                    case 4:
                    if(this.subsel != 0){
                        this.page = id   
                        this.createclass = 'stagecomp'
                        this.line3class = 'stageselectlinecomp'    
                        }
                    break

                }
            }, 

         checkStock(){
                this.subtypes = []
                axios.get('/api/casterntripsubscriptionsstock2',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        dealer:this.dealersel}
                })
				.then((response) => {
                    if(response.data.code == 999){ 
                                    this.errorresponse = response.data
                                    } else {
                                        if(response.data.CasterSubs.length == 0){this.nosubstock = !this.nosubstock}
                                            } // else  if(this.companysel !== 0 ){this.fetchCompany()}
                    });
        },     

         closemodal(){
              this.editmachine = false
              //this.$emit('closemodal')
         },

		}	   
	
	};

</script>