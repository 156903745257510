<template>
<div>
<b-container class="mainpagecont">

<b-row>
    <b-col class="col-12 col-sm-6 p-2">
        <h3>Reference Stations</h3>
        <h4>{{  reseller.text }}</h4>
    </b-col>
    <b-col class="col-12 col-sm-6 text-left text-sm-right" v-if="role == 1">
        <b-button size="sm" variant="outline-success" class="countrybutton" @click.stop="fetchReseller(0)">All</b-button>
        <b-button size="sm" variant="outline-success" class="countrybutton" @click.stop="fetchReseller(1)">Cloudbase</b-button>
        <b-button size="sm" variant="outline-success" class="countrybutton" @click.stop="fetchReseller(11)">RTK Danmark</b-button>
       
    </b-col>    
</b-row>    

<b-row>
    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterBasestationsCard  :singlebox="true" />
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterShowLeaflet v-if="showmap"  :roverslist="mappoints"  :border="true" v-on:fullscreen="fullScreen" />
    </b-col>

    <b-col class="col-12 mt-1 p-2">

        <div class="imageBorder">

            <b-row class="mt-3">
            <b-col class="col-10 offset-1 col-md-4 offset-md-4">
                    <CasterSearch v-on:textsearch=updateSearch v-on:clearsearch=clearFilter />
                </b-col>
            </b-row>

            <b-row class="mt-3" v-if="refstationslist.length > 0">
            <b-col class="col-12 p-3">
                    <b-table
                        striped 
                        responsive 
                        hover 
                        :items="refstationslist" 
                        :fields="fields"
                        sort-icon-left
                        :sort-by="sortBy"
                        :sort-desc="sortDesc"
                        tbody-tr-class="rowClass"
                        thead-tr-class="rowHClass"
                        @row-clicked="menuLink($event)"
                        dark
                        >
                        <template #cell(statuscolor)="data">
                        <div class="rtmarker" :style="{ backgroundColor:data.item.statuscolor }" />
                        </template>

                        <template #cell(conntime)="data">
                        {{ calcConnTime(data.item)}}
                        </template>

                        </b-table>
                    
            </b-col>
            </b-row>

            <b-row class="mt-3" v-else>
            <b-col class="col-12 text-center pt-3 pb-3">
                <h5>No Reference Stations Found</h5>
            </b-col>   
            </b-row>

        </div>

    </b-col>
</b-row>

<b-modal ref="full-screen" size="xl"  title="REFERENCE STATIONS MAP" @show="waitSetWidth" @hide="closeMap" hide-footer centered content-class="darkmodal2">
    <div ref="fullscreendiv" :style="{width:100+'%',height:500+'px'}">
        <CasterShowLeaflet v-if="modalmapwidth != 0" :roverslist="mappoints" :fsmapheight="modalmapheight" :fsmapwidth="modalmapwidth" :networkdisplay="1"/>   
   </div>
</b-modal> 

</b-container>	
</div> 
</template>


<script>
    import CasterBasestationsCard from './CasterBasestationsCard'
    import CasterShowLeaflet from './CasterShowLeaflet'
    import CasterSearch from './CasterSearch'
    import { mapState } from 'vuex'
    import axios from 'axios'
    import moment from 'moment'
	export default {
        name:'CasterReferenceStation',
        components:{
            CasterSearch,
            CasterBasestationsCard,
            CasterShowLeaflet,
        },
        data(){
        return {
                reseller:{
                    value:0,
                    title:'-',
                    country:0
                },
                resellerid:0,
                basestations:[],
                mappoints:[],
                showmap:false,
                modalmapwidth:0,
                modalmapheight:550,
                boxw:0,
                boxh:0,
                role:0,
                refstationslist:[],
                fields:[
                { key: 'statuscolor', label: 'Status', sortable: true}, 
                { key: 'text', label: 'Name', sortable: true },
                { key: 'type', label: 'Type', sortable: true },
                { key: 'statustext', label: 'Status', sortable: true},
                { key: 'connecta', label: 'Connecta', sortable: true},
                { key: 'topcomm', label: 'TC', sortable: true},
                { key: 'gsats', label: '# GPS', sortable: true},
                { key: 'rsats', label: '# GLONASS', sortable: true},
                { key: 'connected_time', label: 'Connected Time', formatter: 'formatTime',sortable: true},
                { key: 'conntime', label: 'Connection Time', sortable: true},
                { key: 'datausage', label: 'Data Use MB', sortable: true},
                { key: 'avail', label: '% Avail', sortable: true},   
                ],
                sortBy: 'text',
                sortDesc: false,
                searchtext:'',
                };
                
                },

        created(){
            this.token = this.$store.getters.token
            this.role = this.$store.getters.roleid
            this.resellerid = this.$store.getters.resellerid
        },

         mounted(){
            this.$store.commit('setbasesdatapoll',1)
            this.$store.commit('setroversdatapoll',0)
            this.fetchReseller(this.resellerid)
            this.showRefStationsData()
        },

        beforeDestroy () {
            window.removeEventListener("resize",this.setModalWidth)
        },

        computed: mapState(['basesdata']),
    
        watch: {
                    basesdata: function () {
                        this.showRefStationsData()
                    }, 
            }, 

        methods:{

            fetchReseller(resellerid){
                axios.get('/api/casterbusinesses',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.token,
                        id:resellerid
                     }
                })
				.then((response) => {
                   
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {
                        this.reseller = response.data.CasterBusiness[0]
                        if(resellerid == 0){
                            this.reseller.text = 'All'
                            this.reseller.country = 0
                        }
                        this.$store.commit('setresellerid',resellerid) 
                        this.$store.commit('setcountryid',this.reseller.country)
                        this.$store.commit('setroversdata','')
                        this.display = true
                      }
                    });
			},

            showRefStationsData(){  //  Get basestations  data
            
                    if(this.$store.getters.basesdata.basestations != undefined){
                        this.basestations = this.$store.getters.basesdata.basestations
                        let mappoints = []
                        this.basestations.forEach(element => {
                            mappoints.push({id:element.value,latitude:element.latitude,longitude:element.longitude,color:element.statuscolor,name:element.text,status:element.mapstatus})
                        });
                       // if(JSON.stringify(mappoints) !== JSON.stringify(this.mappoints)){
                            this.mappoints = mappoints
                       //     }
                       
                        this.refstationslist = this.basesdata.basestations   
                             if(this.searchtext != ''){
                                this.refstationslist = this.refstationslist.filter(item => item.text.includes(this.searchtext.toUpperCase()))
                                }

                        this.showmap = true  
                    } else this.showmap = false

                   // setTimeout(this.showRefStationsData,5000)

                },  

        
            clearFilter(){
                this.searchtext = ''
                this.showRefStationsData()
            },
            
            updateSearch(text){
                this.searchtext = text
                if(this.searchtext != ''){
                    this.refstationslist = this.refstationslist.filter(item => item.text.includes(this.searchtext.toUpperCase()))
                    }
            },
            
            menuLink(event){
                this.$router.push({ name: 'casterreferencestation', params: {id:event.value} }).catch(()=>{})
            },

            formatTime(timedate) {
                if(timedate == null || timedate == '-'){return '-'}
                return  moment.utc(timedate).local().format('DD-MM-YY HH:mm:ss')
            },

            calcConnTime(data){
                if(data.status == 0){
                    data.connected_time = '-'
                    data.gsats = '-'
                    data.rsats = '-'
                    return '-'
                }
                if(data.type !== 4){
                    data.gsats = 'n/a'
                    data.rsats = 'n/a'
                    data.datausage = 'n/a'
                }
                let date1 = moment()
                let date2 = moment.utc(data.connected_time).local() 
                let diff = date1.diff(date2, 'seconds')
                return this.calculateTime(diff)
            },

            calculateTime(start){
                    let days = parseInt(start/86400)
                    let hours = Math.trunc(parseInt(start - (days * 86400))/3600) 
                    let minutes = Math.trunc(parseInt((start - (days * 86400) -(hours * 3600))/60)) 
                    let secs = Math.trunc((start - (days * 86400) - (hours * 3600) - (minutes * 60))) 
                    return days+'d '+ hours +'h '+ minutes +'m '+ secs+'s'
                },

                showMap(){
                    this.showmap = true
                },

            fullScreen(){
                this.modalmapwidth = 0
                this.$refs['full-screen'].show()
            },

            waitSetWidth(){
                setTimeout(this.setModalWidth,150)
            },

            setModalWidth(){
                window.addEventListener("resize", this.setModalWidth)
                this.modalmapwidth = this.$refs['fullscreendiv'].clientWidth
            },

            closeMap(){
                window.removeEventListener("resize", this.setModalWidth)
                this.$refs['full-screen'].hide()
            },
    
        }

	};

</script>