<template>
<div>

<b-container>

<span v-if="!updating">

<b-row class="mt-3">
 
    <b-col class="col-12 col-md-6 mt-1">

				<label>NTRIP Username</label>
				<b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="sub.username"
                    class="darkinput"
                    :disabled ="adminlock2" 
                    />
		</b-col>
		
        <b-col class="col-12 col-md-6 mt-1">

				<label>NTRIP Password</label>
				<b-form-input 
                    type="search" 
                    placeholder="password" 
                    autocomplete="off"
                    v-model="sub.password"
                    class="darkinput"
                    :disabled ="adminlock2"   
                    />
		</b-col> 

         <b-col class="col-12 col-md-6 mt-3">

				<label>Connection IP/URL</label>
				<b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="sub.connection"
                    class="darkinput"
                    :disabled ="adminlock"
                    />
		</b-col>
		
        <b-col class="col-12 col-md-6 mt-3">

				<label>Connection Port</label>
				<b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="sub.port"
                    class="darkinput"
                    :disabled ="adminlock"
                    />
		</b-col> 

     </b-row>

     <b-row v-if="roleid == 1"> 
            <b-col class="col-12 col-md-6 mt-3">
                 <label style="margin-right:5px;">Needs GGA</label>
                  <b-form-checkbox
                    v-model="sub.needs_gga"
                    size="lg"
                    value=1
                    unchecked-value=0
                    :disabled="adminlock"
                    />    
            </b-col>

            <b-col class="col-12 col-md-6 mt-3">
                    <label style="margin-right:5px;">Disconnect on change Base</label>
                    <b-form-checkbox
                    v-model="sub.disconnect_onchangebase"
                    size="lg"
                    value=1
                    unchecked-value=0
                    :disabled="adminlock"
                    />    
            </b-col>  
     </b-row>       

        <b-row> 

            <b-col class="col-12 col-md-6 mt-3" v-if="!nosimmswarning || simms.length > 1">
                    <label>SIM Card</label>
                    <b-form-select
                        v-model="simsel"
                        :options="simms"
                        @change="setSimm()"
                        class="darkinput"
                        name="simsel"
                        v-validate="{min_value:1}"
                        :class="{'b-form-select': true, 'is-danger': errors.has('simsel') }"
                        :disabled="nosimm == 1 || sub.status == 3"
                    /> 
            </b-col>
            
            <b-col class="col-12 col-md-6 mt-3" v-else>
                    <label>SIM Card</label><br/>
                    <b-btn variant="danger" size="sm" disabled>No dealer sims in stock</b-btn>
            </b-col> 


        <b-col class="col-12 col-md-6 mt-5">
                <b-form-checkbox
                    v-model="nosimm"
                    inline
                    value=1
                    unchecked-value=0
                    size="lg"
                    :disabled="nosimmswarning"
              />
              <label>Not Required</label>
            </b-col> 
            
        </b-row>

        <b-row>          

     <b-col class="col-12 col-sm-6 mt-3"> 
            <label>Type</label>
                <b-form-select
                    v-model="subsel"
                    :options="subtypes"
                    name="subsel"
                    @change="setSub($event)"
                    v-validate="{min_value:1}"
					:class="{'b-form-select': true, 'is-danger': errors.has('subsel') }"  
                    :disabled="sub.status == 6 || sub.status == 3"
                    />
                </b-col>

        <b-col class="col-12 col-sm-6 mt-3">    
                <label>Reference</label>
				<b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="sub.purchase_order"
                    class="darkinput"
                    />
            </b-col>
       
        <b-col class="col-12 col-sm-6 mt-3">
                <label>Activation Date</label>

                 <VueCtkDateTimePicker
                            v-model="datesel1"
                            format='DD-MM-YYYY HH:mm'
                            :dark="true"
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="datesel1" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput"
                                name="datesel1"
                                v-validate="{required:true}"
                                :class="{'b-form-select': true, 'is-danger': errors.has('datesel1') }"
                                :disabled="subsel === 0 || sub.status == 6 || sub.status == 3" 
                                />
                            </VueCtkDateTimePicker>    

        </b-col>   

        <b-col class="col-12 col-sm-6 mt-3">
                <label>Expiration Date</label>
                <VueCtkDateTimePicker
                            v-model="expire"
                            format='DD-MM-YYYY HH:mm'
                            :dark="true"
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="expire" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput"
                                name="expire"
                                v-validate="{required:true}"
                                :class="{'b-form-select': true, 'is-danger': errors.has('expire') }"
                                :disabled="subsel === 0 && datesel1==='' || sub.status == 6 || adminlock || (sub.status == 3 && !adminlock)" 
                                />
                            </VueCtkDateTimePicker>    

        </b-col>   
       
</b-row>


<b-row v-if="nostocksubs">

<b-col class="col-12 mt-3">    
     <b-button variant="danger" block disabled>NO SUBSCRIPTIONS IN STOCK</b-button>
</b-col>

</b-row>

<b-row class="mt-5" v-if="sdata">
         <b-col class="col-12">         
            <b-button variant="outline-success" block size="lg"  @click="sendSubData()">Next</b-button>
        </b-col>  
</b-row>          

</span>

<span class="text-center text-warning my-2" v-if="updating">
        <h5>
        <b-spinner class="align-middle"></b-spinner>
        <strong><span style="margin-left:30px;margin-top:10px">Updating...</span></strong>
        </h5>
</span>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</b-container>
	
</div> 
</template>


<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'

	export default {
        props:['sdata','reseller','dealer','company'],
        name:'CasterSubscriptionManage',
        components:{
            ErrorLogging
        },
		data(){
		return {
			token:'',
            edit:false,
            //dealer:'',
            sub:{
                roleid:0,
                id:0,
                dealer:0,
                company:0,
                user:0,
                edituser:0,
                rover:0,
                username:'',
                password:'',
                purchase_order:'',
                port:0,
                connection:'',
                needs_gga:0,
                disconnect_onchangebase:1,
                subid:0,
                type:0,
                status:0,
                start:'',
                end:'',
                years:'',
                months:'',
                days:'',
                value:0,
                nosimm:0,
                simm:0,
                simmtext:'',
                simmstatus:0,
            },
            subtypes:[],
            simms:[],
            simsel:0,
            nosimm:1,
            nosimmswarning:false,
            subsel:0,
            dealersel:0,
            companysel:0,
            roversel:0,
            date:0,
            datesel1:'',
            expire:'',
            activestart:'',
            activeend:'',
            updating:false,
            user:{},
            roleid:0,
            nostocksubs:false,
            adminlock:true,
            adminlock2:true,
            errorresponse:{},
        };
		
		},

        mounted(){
            this.user = this.$store.getters.user
            this.roleid = this.$store.getters.roleid
            this.token = this.$store.getters.token
            this.companysel = this.company
            this.sub.user = this.$store.getters.user.id //  Set user made change
            this.datesel1 = moment().format('DD-MM-Y HH:mm:ss')
            
            if(this.roleid == 1){ // System admin
                this.adminlock = false
                this.adminlock2 = false
            }

            if(this.user.change_username){
                this.adminlock2 = false
            }

            console.log(this.sdata)
           
            if(this.sdata.id == undefined){ // New Rover
                   
                    this.dealersel = this.dealer
                    this.sub.dealer = this.dealer
                    this.roversel = 0
                    this.fetchSubStock()
                    this.fetchSimms()
                    this.createNtripUsername()
                    this.startDateWatcher()
                    this.fetchDealer()
                    this.simsel = 0
                    this.subsel = 0
                    this.edit = false

            } else {                        // Edit Rover
                   
                    this.sub = this.sdata 
                    this.dealersel = this.sub.dealer
                    this.fetchSubStock()        //  Rover add back to subscription
                    this.fetchSimms()   
                    this.fetchDealer()
                    this.roversel = this.sub.rover  
                    this.nosimm = this.sub.nosimm
                    this.simsel = this.sub.simm
                    this.subsel = this.sub.subid
                    this.datesel1 = moment(this.sub.start).format('DD-MM-Y HH:mm:ss')
                    this.expire = moment(this.sub.end).format('DD-MM-Y HH:mm:ss')
                    this.activestart = this.datesel1
                    this.activeend = this.expire
                    this.edit = true
            }

          
        },

        watch:{
            nosimm: function () {
                   if(this.nosimm == 1){
                       this.simsel = 0
                       this.sub.simm = 0
                       this.sub.nosimm = 1
                       this.sub.simmtext = 'Not Required'
                       this.nosimmswarning = false
                    } else if(this.simms.length == 1){this.nosimmswarning = true} 
                      
                }, 
         },
	
		methods: {

            startDateWatcher() {
                this.$watch('datesel1', function() {
                    if(this.datesel1 !== ''){this.setDate()}
                });
            },

            setSub(event){  
                let data = this.subtypes.filter(sub => sub.value == event)
                this.sub.type = data[0].type
                this.sub.subid = data[0].value
                this.sub.title = data[0].text
                this.sub.days = data[0].days
                this.sub.months = data[0].months
                this.sub.years = data[0].years
                this.$validator.validate('subsel')
                this.expire = ''
                
                if(data[0].id == 1){ // is active sub so use current start/end dates
                    this.datesel1 = this.activestart
                    this.expire = this.activeend
                } else {
                    this.datesel1 = moment().format('DD-MM-Y HH:mm:ss')
                    this.setDate()
                    }
            },

            setDate(){
                let datetime = moment(this.datesel1, "DD-MM-YYYY HH:mm")
              
                if(this.sub.days !== 0){
                    this.sub.end = moment(datetime).add(this.sub.days, 'days')
                }
                if(this.sub.months !== 0){
                    this.sub.end = moment(datetime).add(this.sub.months, 'months')
                }
                if(this.sub.years !== 0){
                    this.sub.end = moment(datetime).add(this.sub.years, 'years')
                }
                this.expire = moment(this.sub.end).format('DD-MM-YYYY HH:mm:ss')
                this.sub.start = moment(datetime).format('DD-MM-YYYY HH:mm:ss')
                this.sub.end = this.expire // moment(this.sub.end).format('DD-MM-YYYY HH:mm:ss')

                this.$validator.validate('datesel1')
                this.$validator.validate('expire')
            },

       
            fetchSubStock(){
                this.subtypes = []
                axios.get('/api/casterntripsubscriptionsstock2',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        dealer:this.dealersel,
                        rover:this.roversel
                        }
                })
				.then((response) => {
                    this.subtypes.push({value:0,text:'Select'})
                    if(this.edit){
                        this.subtypes.push({
                            value:this.sub.subid,
                            text:this.sub.title+'- active',
                            type:this.sub.type,
                            id:1,
                            years:this.sub.years,
                            months:this.sub.months,
                            days:this.sub.days,
                        })
                    }
                    this.subtypes.push(...response.data.CasterSubs) // If new sub just show stock subs

                   
                    if(response.data.CasterSubs.length == 0){
                         this.$swal({
                            title: "Warning",
                            text: 'No stock subscriptions available for dealer',
                            icon: "warning",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    }


                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
        
			},
   
            fetchSimms(){
                axios.get('/api/castersimmsstock',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        dealer:this.dealersel,
                        rover:this.roversel}
                })
				.then((response) => {
                    this.simms.push({value:0,text:'Select'})
                    if(this.edit && this.sub.simm > 0){
                        this.simms.push({value:this.sub.simm,text:this.sub.simmtext+'- active'})
                    }
                    this.simms.push(...response.data.CasterSimms)
                    if(this.simms.length == 1){this.nosimmswarning = true} 
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            })
                    });
           },

            setSimm(){
                let sim = this.simms.filter(simm=>simm.value == this.simsel)
                this.sub.simmtext = sim[0].number
                this.sub.simm = this.simsel
            },

            sendSubData(){  //  Send data to parent

                        this.$validator.validateAll().then(result => {
                        if (result) {
                                this.sub.end = this.expire
                                this.sub.nosimm = this.nosimm
                                this.$emit('updatesub',this.sub)
                                }
                        })
            },

            createNtripUsername(){
                axios.get('/api/getnewroverusername',{
                            headers: {"Authorization" : "Bearer " + this.token},
                            params:{
                                userid:this.$store.getters.user.id,
                                token:this.$store.getters.user.token,
                                id:this.companysel
                                }
                            })
                            .then((response) => {
                                if(response.data.code == 999){
                                    this.errorresponse = response.data
                                    } else {
                                        this.sub.username = response.data.username
                                        this.sub.password = this.createNtripPassword()
                                        }
                            });
            },    
              
            createNtripPassword(){
                        let pwdChars = "23456789ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz"
                        let pwdLen = 8
                        return Array(pwdLen).fill(pwdChars).map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('')
            },
                
            async waitcheckName(name){
                await this.checkName(name)
            },

            async checkName(rovername){        // Check company name not in use
                    return new Promise((resolve) => {
                    
                        axios.get('/api/castercompanies',{
                        headers: {"Authorization" : "Bearer " + this.token},
                        params:{
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            stext:rovername
                            }
                        })
                        .then((response) => {
                                if(response.data.code == 999){
                                    this.errorresponse = response.data
                                    } else {
                                if(response.data.length == 0){
                                    resolve(false)
                                } else resolve(true)
                            
                                return 
                                }
                            });

                    });        
            },

            fetchDealer(){

				axios.get('/api/casterdealers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         id:this.dealersel,
                         list:1
                     }
                })
				.then((response) => {
                    let dealer = response.data.CasterDealers[0]
                    let connection = dealer.rtk_url
                    if(dealer.business == 11){connection = 'rtkdk.io'}

                    if(dealer.jd_dealer == 1){

                          if(dealer.business == 11){connection = 'jdmrtk.rtkdk.io'}  
                    
                            else {
                            switch(dealer.value){
                                case 4: // Ripon
                                connection = 'jdmrtk.rfs-rtk.io'
                                break;
                                case 14: // Faroll
                                connection = 'jdmrtk.farol-rtk.io'
                                break;
                                default: // Any other JD Dealer
                                connection = 'jdmrtk.cloudbase-rtk.io'    
                                }

                                }
                        }

                    this.sub.connection = connection
                    this.sub.port = 2101
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},
            }
	}
</script> 